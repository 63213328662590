import React, { Component } from "react";
import PostDetail from "../../components/PostDetail.js";
import DetailHeader from "../../components/DetailHeader.js";
import { withRouter } from 'react-router-dom';

 
class DetailPage extends Component { 

  constructor(props) {
    super(props); 
    this.state = { 
      title: "",
      content:"",
      isLoading: true, 
      loadEmptyHint:"加载中…"
    };
  }

    refreshTitle = (title) =>{
        this.setState({
            title:title
        });
    }
  componentDidMount() {
  }


  render() {
    return (      
      // this.postList.length >0 ?
      <div>
        <DetailHeader title={this.state.title}/>
        <PostDetail 
          postId={this.props.match.params.postId}
          refreshTitle={this.refreshTitle}
        /> 
        </div>
    );
  }
}
export default withRouter(DetailPage); 