import { ListView, PullToRefresh } from "antd-mobile";
import { Loading, Image } from "antd-mobile-v5";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import LazyLoad from '../components/LazyLoad';

import "@/css/readList.css";
import "@/css/write.css";
import default_pic from "@/icon/avater/default.png";

import {
  getSessionData,
  formatTime,
  removeSessionData,
  setSessionData,
} from "@/util/commonUtil";
import { fetchAll } from "@/util/HttpUtil";
import UserPortrait from "./UserPortrait";
import {getLocalData} from "../util/commonUtil";

export default class PostList extends Component {
  postList = [];
  scrollTop = 0;
  totalPage = 0;
  noMoreRecords = false;
  queryPostApi = "";
  queryParams = "";

  constructor(props) {
    super(props);
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });
    this.state = {
      dataSource: dataSource.cloneWithRows({}),
      isLoading: true,
      page: 1,
      height: document.documentElement.clientHeight * 3 / 5,
      longitude: 121,
      latitude: 31,
    };
    this.lv = React.createRef();
  }

  //props将要被改变前执行,父组件更新调用
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps !== this.props || nextProps.queryParams !== this.props.queryParams) {
  //     this.queryPostApi = nextProps.queryPostApi
  //     this.queryParams = nextProps.queryParams
  //     this.beginNewSearch();
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props || prevProps.queryParams !== this.props.queryParams) {
      this.queryPostApi = this.props.queryPostApi
      this.queryParams = this.props.queryParams
      this.beginNewSearch();
    }
  }

  beginNewSearch(){
    this.postList = [];
    this.setState({page: 1, dataSource: this.state.dataSource.cloneWithRows(this.postList)})
    let url = this.queryPostApi + "?1=1" + this.queryParams + "&page=1&size=10";
    this.getPostList(url, true);
  }

  //判断是否返回回来的；如果是返回的加载之前本地保留的数据，否则从后台加载
  componentDidMount() {
    this.setState({ height: document.documentElement.clientHeight * 4 / 5 });

    let cachedDatas = getSessionData(this.props.cacheKey);
    if (cachedDatas) {
      let { previous_scrollTop, previous_page, previous_postList } = cachedDatas;
      this.scrollTop = 0 || previous_scrollTop;
      this.postList = JSON.parse(previous_postList);
      //取到原先的postlist的话就结束，取不到就往下继续向后台请求
      if (
          this.postList &&
          this.postList.length > 0 &&
          this.postList[0] !== null
      ) {
        this.setState({
          dataSource: this.state.dataSource.cloneWithRows(this.postList),
          isLoading: false,
          page: previous_page,
        });

        //为了能取到
        setTimeout(() => {
          if (this.lv && this.lv.current)
            this.lv.current.scrollTo(0, this.scrollTop);
        }, 200);
        // }
        return;
      }
    }

    if(this.queryPostApi === ""){
      this.queryPostApi = this.props.queryPostApi;
      this.queryParams = this.props.queryParams;
      this.beginNewSearch();
    }
  }

  //退出时保存当前页信息，以便在返回时不重新加载，而是回到上次浏览的状态
  componentWillUnmount() {
    //防止报错：Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application
    this.setState = (state,callback)=>{
      return;
    };
    if(this.props.cacheKey && this.props.cacheKey !== "") {
      if (!this.postList || this.postList.length === 0) {
        removeSessionData(this.props.cacheKey);
      } else {
        var currentMap = {
          previous_post_flag: "yes",
          previous_page: this.state.page,
          previous_postList: JSON.stringify(this.postList),
          previous_scrollTop: this.scrollTop,
        };
        setSessionData(this.props.cacheKey, currentMap);
      }
    }
  }

  getPostList(url, isNewSearch) {
    fetchAll(url)
        .then(res => {
          if(isNewSearch) {
            this.postList = [];
            this.setState({page: 1, dataSource: this.state.dataSource.cloneWithRows(this.postList)})
          }
          //没取到，表示到最后一页了
          if (res.result === "failed" || (res.founds && res.founds.length === 0)) {
            this.noMoreRecords = true;
            this.setState({
              isLoading: false,
            });
            return;
          } else {
            this.noMoreRecords = false; //为了到达最后后，又重新刷新
          }

          this.postList = this.postList.concat(res.founds);
          this.setState({
            dataSource: this.state.dataSource.cloneWithRows(this.postList),
            isLoading: false,
            page: this.state.page + 1,
          });
        })
        .catch(err => {
          this.postList = [];
          this.setState({
            isLoading: false,
          });
        });
  }

  refreshPage = () => {
    this.postList = [];
    this.setState({dataSource: this.state.dataSource.cloneWithRows(this.postList)})
    this.noMoreRecords = false;
    this.setState({ page: 1 });
    if(this.queryPostApi === ""){
      this.queryPostApi = this.props.queryPostApi;
      this.queryParams = this.props.queryParams;
    }
    this.setState({ refreshing: true, isLoading: true });
    setTimeout(() => {
      this.beginNewSearch();
      this.setState({
        refreshing: false,
        isLoading: false,
      });
    }, 600);
  };

  //为了取到滚动条位置
  onScroll = event => {
    this.scrollTop = event.target.scrollTop;
  };

  onEndReached = event => {
    //如果已经到达最后一页，不再加载了
    if (this.noMoreRecords) {
      this.setState({ isLoading: false });
      return;
    }
    this.setState({ isLoading: true });
    let url = this.queryPostApi + "?1=1" + this.queryParams + `&page=${this.state.page}` + `&size=10`;
    this.getPostList(url, false);
  };

  backToTop=()=>{
    setTimeout(() => {
      if (this.lv && this.lv.current)
        this.lv.current.scrollTo(0, 0);
    }, 100);
  }

  render() {
    const _renderSeparator = (sectionID, rowID) => (
        <div
            key={`${sectionID}-${rowID}`}
            style={{
              backgroundColor: "#F5F5F9",
              height: 10,
              borderTop: "1px solid #ECECED",
              borderBottom: "1px solid #ECECED",
            }}
        />
    );

    const _renderRow = rowData => {
      if(!rowData){
        return (<div/>)
      }
      return (
          /*parentPostId不为空表示该帖子是跟帖，用于我的发布等场合*/
          <Link to={rowData.parentPostId?`/detail/${rowData.parentPostId}`:`/detail/${rowData.id}`} key={rowData.id}>
            <div className="post_div" key={rowData.id}>
              <div className="portrait_and_time">
                  <UserPortrait
                      portraitlUrl = {rowData.userInfo?rowData.userInfo.portraitThumbnailUrl:""}
                      displayName = {rowData.userInfo?rowData.userInfo.displayName:""}
                  />
                  <span style={{"fontSize":"12px"}}>{formatTime(rowData.createTime,'YYYY-MM-DD hh:mm:ss')}</span>
              </div>
              <div className="post_title">
                {
                  rowData.category && rowData.category.startsWith("recycle")
                      ? ("【" + (rowData.type === "sell" ? "出售" : (rowData.type === "buy" ? "求购": "赠送")) + "】" + rowData.title)
                      : rowData.title
                }
              </div>
              <div className="post_article">
                {
                  rowData.content && rowData.content.length > 100
                      ? <div>{rowData.content.substr(0, 100)}<span style={{"color":"rgb(130,190,110)"}}>...全文</span></div>
                      : rowData.content
                }
              </div>
              <div style={rowData.pictures ? {"display":"flex","justifyContent":"space-around","padding":"6px"}: {"display":"none"}}>
                {
                  rowData.pictures ? rowData.pictures.slice(0,3).map((pic, index) => (
                      <span>
                        {/*<Image*/}
                        {/*    src={pic.thumbnailUrl}*/}
                        {/*    width={100} height={100} fit='cover'*/}
                        {/*/>*/}

                        <LazyLoad
                            state={{
                              datasrc: pic.thumbnailUrl, //要预加载的图片(这是请求接口渲染页面的图片)
                              src: default_pic,    //默认图片 (在请求图片没有加载出来之前默认显示的图片)
                              BoxClassName: "load-more-kmr", // 这是容器的类名
                              ImgClassName: "load-more-kmr", // 这是img的类名
                            }}
                        />
                      </span>
                  ))
                      : ""
                }
              </div>
              <div className="post_remark">
                <div className="house_svg" />
                <span>
                  {rowData.resiQuarterInfo ? rowData.resiQuarterInfo.name : "小区"}
                </span>
              </div>
            </div>
          </Link>
      );
    };

    return (
        <div>
          <div className="write_button_div"  style={{"margin-bottom": "20px"}}>
            <Link to={"/write/" + this.props.mainCategory}>
              <div className="write_button" />
            </Link>
          </div>

          <div className="back_top_div" onClick={this.backToTop} style={{"margin-bottom": "20px"}}>
            <div className="back_top_button" />
          </div>
          {this.postList.length === 0 && this.state.isLoading === true ? (
              <div style={{ textAlign: "center" }}>
                加载中<Loading color="currentColor" />{" "}
              </div>
          ) : (
              <ListView
                  id="listview"
                  ref={this.lv}
                  dataSource={this.state.dataSource}
                  initialListSize={
                    this.postList && this.postList.length > 10 ? (this.postList.length) : (10)
                  }
                  renderFooter={() => (
                      <div style={{ padding: 30, textAlign: "center" }}>
                        {this.state.isLoading ? "加载中..." : "真的没有了哦"}
                      </div>
                  )}
                  renderRow={_renderRow}
                  renderSeparator={_renderSeparator}
                  style={{
                    height: this.state.height,
                    overflow: "auto",
                  }}
                  pageSize={10}
                  onScroll={this.onScroll}
                  scrollRenderAheadDistance={500}
                  onEndReached={this.onEndReached}
                  onEndReachedThreshold={10}
                  pullToRefresh={
                    <PullToRefresh
                        refreshing={this.state.refreshing}
                        onRefresh={this.refreshPage}
                    />
                  }
              />
          )}
        </div>
    );
  }
}
