import React, { Component } from 'react'
import { Route, Redirect,withRouter } from 'react-router-dom'

class AuthRouter extends Component {
    render() {
        const { component: Component, ...rest } = this.props
        const isLogged = localStorage.getItem("accountInfo");

        return (
            <Route {...rest} render={props => {
                const prevRouter = props.location.pathname;
                const redirectHref = window.location.href;
                //跳转登录后回跳
                sessionStorage.setItem("redirectHref",redirectHref);
                sessionStorage.setItem("redirectQuery",JSON.stringify(props.location.query));
                //   const to = "/login"+prevRouter;
                const to = "/pwdlogin";
                return isLogged
                    ? <Component {...props} />
                    : <Redirect to={to}/>
            }} />
        )
    }
}

export default withRouter(AuthRouter); 