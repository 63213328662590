import { NavBar, Icon } from "antd-mobile";
import { Select, Button } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { setLocalData, getLocalData } from "../../util/commonUtil";
import {getCityList, getCityMap, getCityMapByAdCode, getCurrentLocation} from '../../util/LocationUtil';

import "../../css/area.less";
import "../../css/rqSelector.less";
import position_png from "../../icon/position.png";

const { Option } = Select;

class Location extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            currentViewCityName: "",
            locationCityName: "正在定位...",
        };
    }

    componentDidMount() {
        let currentCity = getLocalData("current_view_city");
        let cityName = "";
        if(currentCity !== null){
            cityName = currentCity.name;
        }else{
            cityName = "请选择城市";
        }

        this.setState({
            userLogined: false,
            currentViewCityName: cityName
        });
        let location = getCurrentLocation(null, this.onGetCityResult);
        if(location !== null) {
            this.setState({locationCityName : location.cityName});
        }
    }

    onLocationListClicked = (cityName, e) => {
        setLocalData("current_view_city", getCityMap()[cityName]);
        this.setState({currentViewCityName: cityName})
    }

    filterCity(input, cityOption) {
        if(input.trim() === ""){
            return false;
        }
        let keyword = input.trim();
        let optionCityFullName = cityOption.key
        let optionCityInfo = getCityMap()[optionCityFullName];
        return optionCityInfo.name.indexOf(keyword) >= 0 || optionCityInfo.eName.indexOf(keyword.toLowerCase()) >= 0
    }

    useLocationCityClicked = (e) => {
        let location = getCurrentLocation(null, this.onGetCityResult);
        if(location !== null && location.cityAdCode !== null && location.cityAdCode !== "") {
            setLocalData("current_view_city", getCityMapByAdCode()[location.cityAdCode]);
            this.setState({currentViewCityName: getCityMapByAdCode()[location.cityAdCode].name})
        }
    }

    onGetCityResult = (result) => {
        if(result.is_success && result.city){
            this.setState({locationCityName : result.city});
        }
    }

    render() {
        return (
            <div>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        this.props.history.goBack();
                    }}
                >
                    <span style={{"font-size": "18px","margin": "5px"}}>{"您正在看的城市：" + this.state.currentViewCityName}</span>
                </NavBar>
                <div className='current_location'>
                    <div style={{"margin-top": "-5px"}}><img src={position_png}></img></div>
                    <div>{this.state.locationCityName + "  "}
                        <Button onClick={this.useLocationCityClicked} style={{"font-size": "16px"}}>
                            {"查看当前定位城市"}
                        </Button>
                    </div>
                </div>
                <Select id={"selector"}
                        showSearch
                        showArrow={true}
                        optionFilterProp="children"
                        placeholder={"请输入城市名/拼音"}
                        style={{ "width" : "100%","margin-top": "8px"}}
                        onSelect={this.onLocationListClicked}
                        filterOption={this.filterCity}
                        value={this.state.currentViewCityName}
                >
                    {
                        getCityList().map((cityName, index) => (
                        <Option key={cityName} value={cityName}>
                            {cityName}
                        </Option>
                        ))
                    }

                </Select>
            </div>
        );
    }
}

export default withRouter(Location);
