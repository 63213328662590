import { Toast, NavBar,Icon } from "antd-mobile";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {getLocalData, getUserId, setLocalData} from "../../util/commonUtil";
import {fetchBlob, fetchAll} from "../../util/HttpUtil";
import {getCurrentLocation, getCityList, getCityMap, getCityMapByAdCode} from '../../util/LocationUtil';
import "../../css/area.less";
import "../../css/setting.less";
import position_png from "../../icon/position.png";

import {Button, Select} from "antd";
const { Option } = Select;

class SetArea extends Component {
    accountInfo = null;
    constructor(props) {
        super(props);
        this.state = {
            userName: ""
            , myRqName: ""
            , locationCityName: "正在定位..."
        };

        this.currentCityAdCodes = ""
    }

    componentDidMount() {
        this.accountInfo = getLocalData("accountInfo");
        if(this.accountInfo.resiQuarterInfo) {
            let city = getCityMapByAdCode()[this.accountInfo.resiQuarterInfo.cityAdCode];
            this.currentCityAdCodes = city.adCodes;
            this.setState({
                myRqName: this.accountInfo.resiQuarterInfo.name
                , myCityName: this.accountInfo.resiQuarterInfo.cityName
            })
        }else{
            this.setState({
                myRqName: ""
                , myCityName: ""
            })
        }
        let location = getCurrentLocation(null, this.onGetCityResult);
        if(location !== null) {
            this.setState({locationCityName : location.cityName});
        }
    }

    onGetCityResult = (result) => {
        if(result.is_success && result.city){
            this.setState({locationCityName : result.city});
        }
    }

    onRQSelected = (rqId, option)=>{
        let payload = {};
        payload.resiQuarterId = rqId
        let formData = new FormData();
        formData.append("updates",JSON.stringify(payload));

        fetchBlob("/api/v1/accounts/"+getUserId(),"PATCH", formData)
            .then(responseData => {
                this.accountInfo.resiQuarterId=payload.resiQuarterId ;
                this.accountInfo.resiQuarterInfo=responseData.resiQuarterInfo;
                setLocalData("accountInfo",this.accountInfo);
                if(getLocalData("current_view_city") === null){
                    //如果当前查看城市为空，设置小区时，默认设置当前查看小区所在城市
                    setLocalData("current_view_city", getCityMapByAdCode()[responseData.resiQuarterInfo.cityAdCode]);
                }
                Toast.success("设置成功！", 3, null, false);
                this.props.history.goBack();
            })
            .catch(err => {
                Toast.fail("设置失败,请稍后重试", 3, null, false);
            });
    }

    onLocationListClicked = (cityName, e) => {
        let city = getCityMap()[cityName];
        this.currentCityAdCodes = city.adCodes;
        this.setState({myCityName : city.name});
    }

    filterCity(input, cityOption) {
        if(input.trim() === ""){
            return false;
        }
        let keyword = input.trim();
        let optionCityFullName = cityOption.key
        let optionCityInfo = getCityMap()[optionCityFullName];
        return optionCityInfo.name.indexOf(keyword) >= 0 || optionCityInfo.eName.indexOf(keyword.toLowerCase()) >= 0
    }

    onRQKeywordTextChanged = value => {
        if(value.trim() === ""){
            return;
        }
        let cityFilter = ""
        if(this.currentCityAdCodes !== "") {
            cityFilter = "&city-code=" + encodeURIComponent(this.currentCityAdCodes)
        }
        let url = "/api/v1/search/resi-quarters?keyword="+encodeURIComponent(value)+"&page=1&size=10" + cityFilter;
        fetchAll(url)
            .then(res => {
                //没取到，表示到最后一页了
                if (res.founds && res.founds.length === 0) {
                    // this.noMoreRecords=true;
                    //无搜索结果
                }
                this.setState({
                    resiQuarterList: res.founds,
                });
            })
            .catch(err => {
                console.log(err);
            });
    };

    useLocationCityClicked = (e) => {
        let location = getCurrentLocation(null, this.onGetCityResult);
        if(location !== null && location.cityName !== null && location.cityName !== "") {
            let city = getCityMapByAdCode()[location.cityAdCode];
            this.currentCityAdCodes = city.adCodes;
            this.setState({myCityName : location.cityName});
        }
    }

    render() {
        return (
            <div>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        require("history")
                            .createHashHistory()
                            .goBack();
                    }}
                >
                    设置您所在的小区
                </NavBar>
                <div className='current_location'>
                    <div style={{"margin-top": "-5px"}}><img src={position_png}></img></div>
                    <div>
                        {this.state.locationCityName + "  "}
                        <Button onClick={this.useLocationCityClicked} style={{"font-size": "16px"}}>
                            {"使用当前定位城市"}
                        </Button>
                    </div>
                </div>
                <Select id={"selector"}
                        showSearch
                        showArrow={true}
                        optionFilterProp="children"
                        placeholder={"请输入城市名/拼音，选择所在城市"}
                        style={{ "width" : "100%","margin-top": "8px"}}
                        onSelect={this.onLocationListClicked}
                        filterOption={this.filterCity}
                        value={this.state.myCityName !== "" ? this.state.myCityName : "请输入城市名/拼音，选择所在城市"}
                        defaultValue={this.state.myCityName !== "" ? this.state.myCityName : "请输入城市名/拼音，选择所在城市"}
                >
                    {
                        getCityList().map((cityName, index) => (
                            <Option key={cityName} value={cityName}>
                                {cityName}
                            </Option>
                        ))
                    }
                </Select>

                <Select id={"selector"}
                        showSearch
                        showArrow={true}
                        placeholder={"请输入小区名"}
                        style={{ "width" : "100%","margin-top": "8px"}}
                        filterOption={false}
                        onSearch={this.onRQKeywordTextChanged}
                        onSelect={this.onRQSelected}
                        defaultValue={this.state.myRqName !== "" ? this.state.myRqName : "请输入小区名"}
                >
                    {this.state.resiQuarterList ? (
                        this.state.resiQuarterList.map((item, index) => (
                            <Option key={index} value={item.id} name={item.name}>
                                {item.name + "    " + item.cityname + item.adname}
                            </Option>
                        ))
                    ) : (
                        <Option >
                            {/* 暂无查到 */}
                        </Option>
                    )}
                </Select>
            </div>
        );
    }
}

export default withRouter(SetArea);
