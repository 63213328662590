import {
    List,
    InputItem,
    Button,
    Flex,
    Toast,NavBar,Icon
} from "antd-mobile";
import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import "../../css/setting.less";

import {fetchAll, fetchBlob} from "../../util/HttpUtil";
import {getLocalData, setLocalData, getUserId} from "../../util/commonUtil";

import "../../css/vcode.less";

class PhoneBind extends Component {
    constructor(props) {
        super(props);
        if(!this.props.location.query) {
            this.props.location.query = {tips: ""}
        }
        this.vCodeCDFunc = null;
        this.state = {
            phone: ""
            , vCode: ""
            , vCodeCoolingDown: 0
            , vCodeButtonText: "发送验证码"
        };
    }

    componentDidMount() {
    }

    onSendVCodeButtonClicked = () => {
        if(this.state.vCodeCoolingDown > 0){
            return;
        }
        let params = {};
        params.phone = this.state.phone;
        fetchAll("/api/v1/vcode", params,'POST')
            .then(res => {
                //没取到，表示到最后一页了
                if (res.result === "failed") {
                    Toast.fail("获取验证码失败,请稍后重试", 3, null, false)
                }else{
                    this.setState({vCodeCoolingDown: 10})
                    this.vCodeCDFunc = setInterval(this.vCodeCoolingDown, 1000);
                    Toast.info("验证码已发送", 3, null, false)
                }
            })
            .catch(err => {
            });
    }

    vCodeCoolingDown = () => {
        let tmp = this.state.vCodeCoolingDown - 1;
        let text = tmp + "s";
        if(tmp <= 0){
            clearInterval(this.vCodeCDFunc);
            text = "发送验证码"
        }
        this.setState({vCodeCoolingDown: tmp, vCodeButtonText: text})
    }

    onLoginButtonClicked = () => {
        if(this.state.phone === "" || this.state.vCode === ""){
            return;
        }
        let payload = {};
        payload.phone = this.state.phone;
        payload.vCode = this.state.vCode;
        let formData = new FormData();
        formData.append("updates",JSON.stringify(payload));
        fetchBlob("/api/v1/accounts/"+getUserId(),"PATCH", formData)
            .then(responseData => {
                if(responseData.result === "success") {
                    let accountInfo = getLocalData("accountInfo");
                    accountInfo.phone = payload.phone.substr(0, 3) + "****" + payload.phone.substr(7, 4);
                    setLocalData("accountInfo", accountInfo);
                    Toast.success("绑定成功！", 3, null, false);
                    this.props.history.goBack();
                }else{
                    Toast.fail(responseData.err, 3, null, false);
                }
            })
            .catch(err => {
                Toast.fail("绑定失败,请稍后重试", 3, null, false);
            });
    }

    render() {
        return (
            <div>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        require("history").createHashHistory().goBack();
                    }}
                >
                    设置
                </NavBar>
                <List renderHeader={() => this.props.location.query.tips}>
                    <InputItem
                        type={"phone"}
                        placeholder={"手机号"}
                        clear
                        onChange={phone => {
                            //这里会自动在手机号中加入两个空格
                            this.setState({ phone: phone.replace(/ /g, "") });
                        }}
                    >
                        +86
                    </InputItem>
                    <div className={"vcode-input"}>
                        <InputItem
                            type={"number"}
                            placeholder={"请输入6位验证码"}
                            clear
                            maxLength={6}
                            onChange={vCode => {
                                this.setState({ vCode: vCode });
                            }}
                        >
                            验证码
                        </InputItem>
                        <Button
                            type="primary" size={"small"}
                            onClick={this.onSendVCodeButtonClicked}
                            style={{"top":"6px"}}
                            disabled={this.state.phone.length !== 11 || this.state.vCodeCoolingDown > 0}
                        >
                            {this.state.vCodeButtonText}
                        </Button>
                        <div/>
                    </div>
                </List>
                <Flex style={{ padding: '35px' }}>
                    <Flex.Item>
                        <Button type="primary" onClick={this.onLoginButtonClicked} disabled={this.state.phone.length !== 11 || this.state.vCode.length !== 6}>
                            登录
                        </Button>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}

export default withRouter(PhoneBind);
