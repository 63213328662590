import {ImagePicker, Button, Flex, Toast, NavBar, Icon} from "antd-mobile";
import {TextArea} from "antd-mobile-v5";
import React, { Component } from "react";
import {fetchBlob} from "../../util/HttpUtil";
import {getLocalData,getCurrentUser} from "../../util/commonUtil";
import {ImageViewer} from "antd-mobile-v5";

import "@/css/write.css";
import ImgUtil from "../../util/ImgUtil";

export default class WriteReplyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postArticle: "",
            pictureFiles: [],
        };
    }

    componentDidMount() {
        let accountInfo = getLocalData("accountInfo");
        if(!accountInfo || !accountInfo.phone || accountInfo.phone === ""){
            //跳转登录后回跳
            let category = this.props.match.params.category
            setTimeout(this.redirectToBindPage, 1)
        }
        if(this.isEdit()){
            let pics = []
            if(this.props.location.query.editComment.pictures){
                pics = this.props.location.query.editComment.pictures
            }
            this.setState(
                {pictureFiles: pics
                , postArticle: this.props.location.query.editComment.content
                }
            )
        }
    }
    redirectToBindPage = ()=>{
        let category = this.props.match.params.category
        this.props.history.push({pathname:"/bind", query:{"tips": "您未绑定手机，绑定之后才能发文"}})
    }

    isEdit(){
        return this.props.location.query && this.props.location.query.editComment
    }

    //添加图片时，进行压缩
    onImgChange = (pictureFiles, type, index) => {
        //重复对所有图片进行遍历，未压缩的进行压缩，以适应一次上传多个图片的情况
        if(type === 'add') {
            if (pictureFiles.length > 9) {
                Toast.info("最多上传9张图片", 3, null, false);
                pictureFiles = pictureFiles.slice(0, 9)
            }
            pictureFiles.map((file, index) => {
                file.orientation = 1 //保持朝向，不设置的话，框架会自动旋转以满足宽>高，导致图片被左或右旋90度
            });
            for (let i = 0; i < pictureFiles.length; i++) {
                if(!pictureFiles[i].file){
                    continue;//编辑模式，图片是原贴中的url
                }
                let fileSize = pictureFiles[i].file.size / 1024;
                //大于1024k的图片进行压缩
                if (fileSize > 1024) {
                    ImgUtil.transformFile(pictureFiles[i].file).then(value => {
                        pictureFiles[i].file = value;
                    });
                }
            }
        }
        this.setState({
            pictureFiles: pictureFiles,
        });
    };

    onImgClicked = (index, pictureFiles) => {
        let file = pictureFiles[index];
        ImageViewer.show({image: file.url})
    }

    onPublish = e => {
        if(this.isEdit()){
            this.editComment()
        }else{
            this.createComment()
        }
    }

    editComment(){
        let pictureFiles = this.state.pictureFiles;
        let payload = {};
        let currentUser = getCurrentUser();

        if(!currentUser || !getLocalData("token")){
            Toast.fail("登录已过期，请重新登录", 3, null, false);
            return;
        }

        payload.id=this.props.location.query.editComment.id;
        payload.parentPostId=this.props.location.query.editComment.parentPostId;
        payload.content=this.state.postArticle;
        if(!payload.content){
            Toast.fail('请输入内容', 3, null, false);
            return;
        }

        let formData = new FormData();
        let oldPics = []
        for (let i = 0; i < pictureFiles.length; i++) {
            if(pictureFiles[i].file){
                formData.append("pic", pictureFiles[i].file);
            }else{
                oldPics.push(pictureFiles[i])
            }
        }
        payload.pictures = oldPics;
        formData.append("doc",JSON.stringify(payload));

        this.props.history.goBack();//不可以，如果从跳转登录返回的这样会重复跳转--login已修正
        fetchBlob("/api/v1/posts", "PATCH",formData)
            .then(responseData => {
                if(responseData.result === "success") {
                    Toast.success("提交成功，内容将进入审核。审核通过后会自动发布。", 3, null, false);
                }else{
                    Toast.fail(responseData.err, 3, null, false);
                }
            })
            .catch(err => {
                Toast.fail("发文失败,请稍后重试", 3, null, false)
            });
    }

    createComment(){
        let pictureFiles = this.state.pictureFiles;
        let payload = {};
        let currentUser = getCurrentUser();

        if(!currentUser || !getLocalData("token")){
            Toast.fail("登录已过期，请重新登录", 3, null, false);
            return;
        }

        let parentPostId = this.props.match.params.parentPostId;
        let replyToCommentId = this.props.match.params.replyToCommentId;
        payload.parentPostId=parentPostId;
        if(replyToCommentId && parentPostId!==replyToCommentId)
            payload.replyToCommentId=this.props.match.params.replyToCommentId;
        payload.content=this.state.postArticle;
        if(!payload.content){
            Toast.fail('请输入内容', 3, null, false);
            return;
        }
        payload.category = this.props.match.params.category;
        let formData = new FormData();
        formData.append("doc",JSON.stringify(payload));
        for (let i = 0; i < pictureFiles.length; i++) {
            formData.append("pic", pictureFiles[i].file);
        }

        this.props.history.goBack();//不可以，如果从跳转登录返回的这样会重复跳转--login已修正
        fetchBlob("/api/v1/posts", "POST",formData)
            .then(responseData => {
                if(responseData.result === "success") {
                    Toast.success("提交成功，内容将进入审核。审核通过后会自动发布。", 3, null, false);
                }else{
                    Toast.fail(responseData.err, 3, null, false);
                }
            })
            .catch(err => {
                Toast.fail("发文失败,请稍后重试", 3, null, false)
            });
    };

    render() {
        let screenWidth = document.querySelector('body').offsetWidth
        let screenHeight = document.querySelector('body').offsetHeight
        let inputTextRows = 6
        let imagePickerHeight = screenHeight*0.45 + "px"
        let heightWidthRate = screenHeight/screenWidth
        if(heightWidthRate > 1.5){
            imagePickerHeight = screenHeight*0.45 + "px"
            inputTextRows = 6
        }else if(heightWidthRate > 1.3){
            imagePickerHeight = screenHeight*0.3 + "px"
            inputTextRows = 5
        }else{
            imagePickerHeight = screenHeight*0.2 + "px"
            inputTextRows = 4
        }
        return (
            <div className="write_div">
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        require("history").createHashHistory().goBack();
                    }}
                >
                    发表回复
                </NavBar>
                {/*<div>*/}
                {/*    <List.Item style={{display:'block',backgroundColor: '#70BC5B'}}>发表回复 </List.Item>*/}
                {/*</div>*/}
                {/*<br></br>*/}
                <TextArea
                    id="article_input"
                    placeholder="请输入详细描述，更快传递信息"
                    defaultValue={this.isEdit() ? this.props.location.query.editComment.content : ""}
                    rows={inputTextRows}
                    maxLength={500}
                    showCount
                    ref={input => (this.postArticle = input)}
                    onChange={postArticle => {
                        this.setState({ postArticle });
                    }}
                />

                <div style={{"width":"auto","height":imagePickerHeight,"overflow":"auto"}}>
                    <ImagePicker
                        length={3}
                        multiple={true}
                        onChange={this.onImgChange}
                        onImageClick={this.onImgClicked}
                        files={this.state.pictureFiles}
                        selectable={this.state.pictureFiles.length < 9}
                    />
                </div>

                <Flex style={{ padding: "5px" }}>
                    <Flex.Item >
                        <Button type="primary" onClick={this.onPublish}>
                            发布
                        </Button>
                    </Flex.Item>
                </Flex>

            </div>
        );
    }
}
