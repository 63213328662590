import {List, Button, Flex, WhiteSpace} from "antd-mobile";
import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";
import {
  getSessionData,
  getUserId,
  getUserAvatar,
  getLocalData,
  checkNewMsgs,
  setLocalData
} from "../../util/commonUtil";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";

import "@/css/my.less";
import fav_png from "../../icon/fav.png";
import msg_png from "../../icon/msg.png";
import msgsend_png from "../../icon/msgsend.png";
import setting_png from "../../icon/setting.png";
import default_png from "@/icon/avater/default.png";

const Item = List.Item;

// export default
class My extends Component {
  accountInfo = null;

  constructor(props) {
    super(props);
    let hasNewMsg = false;
    let sendersWithNewMsgs = getLocalData("sendersWithNewMsgs");
    if(sendersWithNewMsgs !== null && Object.keys(sendersWithNewMsgs).length > 0){
      hasNewMsg = true;
    }else if(getSessionData("has_new_msg")){
      hasNewMsg = true;
    }
    this.getNewMsgFunc = null;
    this.state = {
      disabled: false,
      logout_display: "none",
      login_display: "block",
      hasNewMsg: hasNewMsg,
      userLogined: false,
      displayName: ""
    };
  }

  componentDidMount() {
    this.getNewMsgFunc = setInterval(this.checkHasNewMsgInterval, 1000);
    let accountInfo = getLocalData("accountInfo");
    let displayName = accountInfo ? accountInfo.displayName : "";
    this.setState({userLogined: accountInfo !== null, displayName: displayName});
  }

  componentWillUnmount() {
    checkNewMsgs();//每次点击页面时，触发一次检查是否有新消息，避免定时检查给服务器带来太大压力
    if(this.getNewMsgFunc !==null ){
      clearInterval(this.getNewMsgFunc);
    }
    //防止报错：Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application
    this.setState = (state,callback)=>{
      return;
    };
  }

  checkHasNewMsgInterval = () => {
    if (getUserId() === null) {
      return;
    }
    let hasNewMsg = false;
    let sendersWithNewMsgs = getLocalData("sendersWithNewMsgs");
    if(sendersWithNewMsgs !== null && Object.keys(sendersWithNewMsgs).length > 0){
      hasNewMsg = true;
    }else if(getSessionData("has_new_msg")){
      hasNewMsg = true;
    }
    this.setState({hasNewMsg: hasNewMsg})
  }

  logout = () => {
    sessionStorage.clear();
    let lastUserName = getLocalData("lastUserName");
    localStorage.clear();
    setLocalData("lastUserName", lastUserName);
    this.setState({
      userLogined: false,
      displayName: ""
    });
  };
  login = () => {
    this.props.history.push("/vCodelogin");
  };

  goMsgList = () => {
    this.props.history.push("/msgList");
  };

  goMyPostList = () => {
    this.props.history.push("/myPostList/" + getUserId());
  };
  goMyCollectList = () => {
    this.props.history.push("/myCollectList/" + getUserId());
  };

  render() {
    return (
      <div>
        <div className="my_avatar">
          <Avatar
            sx={{ width:  80 , height:  80  }}
            alt="icon"
            // variant="rounded"
            src={this.state.userLogined ? getUserAvatar() : default_png}
          />
          <div>
            <div style={{ fontSize: "16px"  }}>
              {this.state.displayName + " "}
            </div>
            <WhiteSpace />
            <div style={{ fontSize: "14px",color:'#707070' }}>
            登录账号: {getLocalData("accountInfo") === null ? "" : getLocalData("accountInfo").userName}
            </div>
          </div>
        </div>

        <WhiteSpace />
        <List renderHeader={() => ""} className="my-list">
          <Item
            arrow="horizontal"
            thumb={msg_png}
            multipleLine
            onClick={this.goMsgList}
          >
            <Badge
                invisible={!this.state.hasNewMsg}
                color={"secondary"}
                variant={"dot"}
                style={{"left": "36px", "top": "-7px"}}
            />
            消息
          </Item>
        </List>

        {/* <List renderHeader={() => "信息"}> */}
        <List renderHeader={() => ""}>
          <Item
            thumb={msgsend_png}
            onClick={this.goMyPostList}
            arrow="horizontal"
          >
            我的发布
          </Item>
          <Item
            thumb={fav_png}
            // multipleLine
            onClick={this.goMyCollectList}
            arrow="horizontal"
          >
            收藏夹
          </Item>
        </List>

        <List renderHeader={() => ""}>
          <Item
            thumb={setting_png}
            arrow="horizontal"
            onClick={() => {
              this.props.history.push("/setting");
            }}
          >
            设置
          </Item>
        </List>

        <Flex style={{ padding: "35px" }}>
          <Flex.Item
            style={{
              display: this.state.userLogined ? "block" : "none",
            }}
          >
            <Button type="primary" shape="circle" onClick={this.logout}>
              退出登录
            </Button>
          </Flex.Item>
          <Flex.Item
            style={{
              display: this.state.userLogined ? "none" : "block",
            }}
          >
            <Button type="primary" onClick={this.login}>
              登录
            </Button>
          </Flex.Item>
        </Flex>
        {/*<Flex style={{ "padding": "15px", "textAlignLast": "center", "display": this.state.userLogined ? "none" : "block"}}>*/}
        {/*  <Flex.Item >*/}
        {/*    <Link to={'/register'}>还没有账号？点此立即注册 </Link>*/}
        {/*  </Flex.Item>*/}
        {/*</Flex>*/}
      </div>
    );
  }
}

export default withRouter(My);
