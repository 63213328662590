import {List, InputItem, Button, Flex, Toast, NavBar, Icon, Checkbox, Modal} from "antd-mobile";
import React, { Component } from "react";
import { withRouter,Link } from "react-router-dom";
import {fetchAll} from "../../util/HttpUtil";
import { setLocalData} from "../../util/commonUtil";

import {getCityMapByAdCode} from "../../util/LocationUtil";
import {Space} from "antd-mobile-v5";

class VCodeLoginPage extends Component {
    constructor(props) {
        super(props);
        this.vCodeCDFunc = null;
        this.state = {
            rediectToReferrer: false // 是否重定向之前的界面
            , login_error_display:'none'
            , phone: ""
            , vCode: ""
            , vCodeCoolingDown: 0
            , vCodeButtonText: "发送验证码"
            , policyRead: false
            , showPolicy: false
            , policyText: "test"
        };
    }
    onPolicyCheck = (e) => {
        this.setState({policyRead: e.target.checked})
    }

    showPolicyDetail = (e) => {
        fetchAll("/api/v1/policy", null, "GET", "text/html")
            .then(responseData => {
                this.setState({policyText: responseData})
            })
            .catch(err => {
                this.setState({policyText: "加载失败，请稍候重试"})
            });
        this.setState({showPolicy: true})
    }

    onPolicyDetailClose =  () => {
        this.setState({
            showPolicy: false,
        });
    }

    onSendVCodeButtonClicked = () => {
        if(!this.state.policyRead){
            Toast.info("登录前请阅读使用协议和个人信息保护政策", 3, null, false);
            return;
        }
        if(this.state.vCodeCoolingDown > 0){
            Toast.fail("不能重复发送，请稍候重试", 3, null, false)
            return;
        }
        if(this.state.phone.length !== 11){
            Toast.fail("手机号码错误", 3, null, false)
            return;
        }
        let params = {};
        params.phone = this.state.phone;
        fetchAll("/api/v1/vcode", params,'POST')
            .then(res => {
                //没取到，表示到最后一页了
                if (res.result === "failed") {
                    Toast.fail("获取验证码失败,请稍后重试", 3, null, false)
                }else{
                    this.setState({vCodeCoolingDown: 10})
                    this.vCodeCDFunc = setInterval(this.vCodeCoolingDown, 1000);
                    Toast.info("验证码已发送", 3, null, false)
                }
            })
            .catch(err => {
            });
    }

    vCodeCoolingDown = () => {
        let tmp = this.state.vCodeCoolingDown - 1;
        let text = tmp + "s";
        if(tmp <= 0){
            clearInterval(this.vCodeCDFunc);
            text = "发送验证码"
        }
        this.setState({vCodeCoolingDown: tmp, vCodeButtonText: text})
    }

    // 提交登录表单
    onLoginButtonClicked = (e) => {
        if(!this.state.policyRead){
            Toast.info("登录前请阅读使用协议和个人信息保护政策", 3, null, false);
            return;
        }
        if (this.state.phone.length === 0 ) {
            this.setState({
                login_error_display: "block",
            });
            return;
        }

        let params = {};
        params.phone = this.state.phone;
        params.vCode = this.state.vCode;

        Promise.race([
            fetchAll("/api/v1/login", params,'POST'),
            new Promise(function(resolve,reject){
                setTimeout(()=> reject(new Error('request timeout')),10000)  //超时
            })])
            .then(responseData => {
                if(responseData.result!=='success'){
                    Toast.fail(responseData.err, 3, null, false);
                    return;
                }
                let {token,accountInfo,refreshToken} = {...responseData};
                setLocalData("token",token);
                setLocalData("refreshToken",refreshToken);
                setLocalData("accountInfo",accountInfo);
                if(accountInfo.resiQuarterInfo && accountInfo.resiQuarterInfo.cityAdCode) {
                    setLocalData("current_view_city", getCityMapByAdCode()[accountInfo.resiQuarterInfo.cityAdCode]);
                }
                const redirectHref = sessionStorage.getItem("redirectHref");
                let redirectUrl='/my';
                if(redirectHref){
                    if(redirectHref.includes('/#/')&&redirectHref.split('/#/')[1]){
                        redirectUrl='/'+redirectHref.split('/#/')[1]||'/my';
                    }else{
                        redirectUrl = redirectHref
                    }
                }
                let redirectQuery = sessionStorage.getItem("redirectQuery");
                if(redirectQuery && redirectQuery !== "" && redirectQuery !== "undefined"){
                    redirectQuery = JSON.parse(redirectQuery);
                }else{
                    redirectQuery = {}
                }
                sessionStorage.removeItem("redirectHref");
                sessionStorage.removeItem("redirectQuery");
                // 登陆成功之后的跳转,用replace而非push，清除/login的历史，以免后续返回的时候回到登录页
                this.props.history.replace({pathname:redirectUrl, query: redirectQuery});

            })
            .catch(err => {
                Toast.fail("遇到点问题，请先歇歇，稍后再试", 3, null, false);
            });
    };

    render() {
        return (
            <div>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        require("history").createHashHistory().goBack();
                    }}
                >
                    登录
                </NavBar>
                <List renderHeader={() => "短信验证码登录(新手机自动注册)"}>
                    <InputItem
                        type={"phone"}
                        placeholder={"手机号"}
                        clear
                        onChange={phone => {
                            //这里会自动在手机号中加入两个空格
                            this.setState({ phone: phone.replace(/ /g, "") });
                        }}
                    >
                        +86
                    </InputItem>
                </List>
                <div className={"vcode-input"}>
                    <InputItem
                        type={"number"}
                        placeholder={"请输入6位验证码"}
                        clear
                        maxLength={6}
                        onChange={vCode => {
                            this.setState({ vCode: vCode });
                        }}
                    >
                        验证码
                    </InputItem>
                    <Button
                        type="primary" size={"small"}
                        onClick={this.onSendVCodeButtonClicked}
                        style={{"top":"6px"}}
                        disabled={this.state.phone.length !== 11 || this.state.vCodeCoolingDown > 0}
                    >
                        {this.state.vCodeButtonText}
                    </Button>
                    <div/>
                </div>
                <Flex style={{ padding: '15px' ,color: "#FF0000" ,display: this.state.login_error_display }}>
                    <Flex.Item>
                        用户名或密码错误，请重试
                    </Flex.Item>
                </Flex>
                <Flex style={{ padding: '35px' }}>
                    <Flex.Item>
                        <Button type="primary" onClick={this.onLoginButtonClicked} disabled={this.state.phone.length !== 11}>
                            登录
                        </Button>
                    </Flex.Item>
                </Flex>
                <Flex style={{ "padding": "15px", "textAlignLast": "center"}}>
                    <Flex.Item>
                        <Space>
                            <Checkbox checked={this.state.policyRead} onChange={this.onPolicyCheck}/>
                            <div>已阅读并同意</div>
                            <div onClick={this.showPolicyDetail} style={{"color": "#1890ff"}}>住这儿使用协议、个人信息保护政策</div>
                        </Space>
                    </Flex.Item>
                </Flex>
                <Flex style={{ "padding": "15px", "textAlignLast": "center"}}>
                    <Flex.Item>
                        <Link replace to={'/pwdlogin'}>账号密码登录 </Link>
                    </Flex.Item>
                </Flex>
                {/*<Flex style={{ "padding": "15px", "text-align-last": "center"}}>*/}
                {/*    <Flex.Item>*/}
                {/*        <Link to={'/register'}>还没有账号？点此立即注册 </Link>*/}
                {/*    </Flex.Item>*/}
                {/*</Flex>*/}

                <Modal style={{"width": "90%","height": "80%" }}
                       visible={this.state.showPolicy}
                       transparent
                       closable
                       onClose={this.onPolicyDetailClose}
                       title="欢迎注册住这儿"
                >
                    <div dangerouslySetInnerHTML={{
                        __html: `${this.state.policyText}`,
                    }}>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withRouter(VCodeLoginPage);
