import AMapLoader from '@amap/amap-jsapi-loader';
import { getSessionData,setSessionData } from "./commonUtil";

import cityInfo from "./city_info.json";

export const cityMap = {} //key：中文全名，value：{name,eName,adCode,adCodes(join with ',')}
export const cityMapByAdCode = {} //key：中文全名，value：{name,eName,adCode,adCodes(join with ',')}
export const cityList = []
let aLocationPlugin = null;

export const getCityList = () => {
    if(cityList.length === 0){
        loadCityInfo();
    }
    return cityList;
}

export const getCityMap = () => {
    if(Object.keys(cityMap).length === 0){
        loadCityInfo();
    }
    return cityMap;
}

export const getCityMapByAdCode = () => {
    if(Object.keys(cityMapByAdCode).length === 0){
        loadCityInfo();
    }
    return cityMapByAdCode;
}

const loadCityInfo = () => {
    let pinyinHeaders = Object.keys(cityInfo)
    for (let i = 0; i < pinyinHeaders.length; i++) {
        let pinyinHeader = pinyinHeaders[i];
        let cities = cityInfo[pinyinHeader];
        for (let j = 0; j < cities.length; j++) {
            let city = cities[j];
            cityList.push(city.name);
            cityMap[city.name] = city;
            cityMapByAdCode[city.adCode] = city;
        }
    }
}

let loading = false;
export const getCurrentLocation = (onGetPositionResult = null, onGetCityResult = null) => {
    if(aLocationPlugin === null && !loading){
        loading = true;
        AMapLoader.load({
            "key": "6c5e373e997132cfedc4ea2d86664ed4",              // 申请好的Web端开发者Key，首次调用 load 时必填
            "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            "plugins": ["AMap.Geolocation"],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap)=>{
            // aMap = new AMap.Map('container');
            aLocationPlugin = new AMap.Geolocation({
                enableHighAccuracy: true,//是否使用高精度定位，默认:true
                timeout: 10000,          //超过10秒后停止定位，默认：无穷大
                maximumAge: 0,           //定位结果缓存0毫秒，默认：0
                convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
            });
            getPosition(onGetPositionResult);
            getCity(onGetCityResult);
            loading = false;
        }).catch(e => {
            loading = false;
            console.log(e);
        })
        return null;
    }

    let currentLocation = getSessionData("location");
    if(!currentLocation){
        getPosition(onGetPositionResult);
        getCity(onGetCityResult);
        return null;
    }else{
        let now = new Date().getTime();
        let lastTs = currentLocation.ts;
        if(now - lastTs >= 30*60*1000){
            getPosition(onGetPositionResult);
            getCity(onGetCityResult);
        }else{
            if(!currentLocation.lat || !currentLocation.lng){
                getPosition(onGetPositionResult);
            }
            if(!currentLocation.cityName || !currentLocation.cityAdCode){
                getCity(onGetCityResult);
            }
        }
        return currentLocation;
    }
};

const locationInterval = 10*60*1000;
let lastGetPositionTime = 0;
const getPosition = (onGetPositionResult) => {
    if(aLocationPlugin === null){
        return;
    }
    let now = new Date();
    if(now.getTime() - lastGetPositionTime <= locationInterval){
        return;//定位不能太频繁
    }
    lastGetPositionTime = now;
    aLocationPlugin.getCurrentPosition((status, result)=>{
        if(status === "complete"){
            let lat = result.position.getLat();
            let lng = result.position.getLng();
            let location = getSessionData("location");
            if(location === null) {
                location = {lat: lat, lng: lng, ts: new Date().getTime()}
            }else{
                location["lat"] = lat;
                location["lng"] = lng;
                location["ts"] = new Date().getTime();
            }
            setSessionData("location", location);
            if(onGetPositionResult){
                onGetPositionResult(result);
            }
        }else{
            if(onGetPositionResult){
                onGetPositionResult(result);
            }
        }
    });
}

let lastGetCityTime = 0;
const getCity = (onGetCityResult) => {
    if(aLocationPlugin === null){
        return;
    }
    let now = new Date();
    if(now.getTime() - lastGetCityTime <= locationInterval){
        return;//定位不能太频繁
    }
    lastGetCityTime = now;
    aLocationPlugin.getCityInfo((status, result)=>{
        if(status === "complete"){
            let location = getSessionData("location");
            if(location === null) {
                location = {cityName: result.city, cityAdCode: result.adcode, ts: new Date().getTime()}
            }else{
                location["cityName"] = result.city;
                location["cityAdCode"] = result.adcode;
                location["ts"] = new Date().getTime();
            }
            setSessionData("location", location);
            if(onGetCityResult){
                result["is_success"] = true
                onGetCityResult(result);
            }
        }else{
            if(onGetCityResult){
                result["is_success"] = false
                onGetCityResult(result);
            }
        }
    });
}