import { List, Button,TextareaItem } from "antd-mobile";
import React, { Component } from "react";
import '../css/postreply.less'

export default class Chat extends Component {
  constructor(){
    super();
    this.state = {
      chatArticle:'你好!请问东西还在吗？'
    }
}
   
onChatSubmit = value =>{ 
    //通过props属性获取父组件的getdata方法，并将this.state值传递过去
  this.props.onChatSubmit(this.state.chatArticle, this.props.postId, this.props.commentId);
}
onChange= value =>{ 
  this.setState({chatArticle:value});
}
onChatCancel= value =>{   
  this.props.onChatCancel();
}
    render() {
        return (     
               <div> 
                <List renderHeader={() => "发送消息"}>
                <TextareaItem 
                  defaultValue=
                  "你好!请问东西还在吗？"
                  rows={6}
                  // autoHeight
                  labelNumber={5}
                  ref='input' 
                  // ref={input => (this.chatArticle = input)}

                  onChange={this.onChange}
                ></TextareaItem>
                </List> 
                <div className="chat_operate">
                  <div></div>
                <Button type="primary" inline size="small"  onClick={this.onChatCancel}>
                  取消
                </Button>
                <div></div>
                <Button type="primary" inline size="small"  onClick={this.onChatSubmit}>
                  发信
                </Button>
                <div></div>
                </div>
              </div>
          );
        };
    
}