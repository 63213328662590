import { ListView, Toast } from 'antd-mobile';
import { Loading,ErrorBlock } from "antd-mobile-v5";
import React, { Component } from "react";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import { withRouter } from "react-router-dom";
import { fetchAll } from "../util/HttpUtil";
import { getUserId, formatTime } from "@/util/commonUtil";
import Chat from "./Chat";
import PostDetailReference from "./PostDetailReference"
import UserPortrait from "../components/UserPortrait"

import "../css/postdetail.less";
import "../css/readList.css";
import "react-photo-view/dist/index.css";
import sold_png from "@/icon/sold.png";
import bought_png from "@/icon/bought.png";
import {RightOutline} from 'antd-mobile-icons';
import {getLocalData} from "../util/commonUtil";

class PostDetail extends Component {
    chatUserId = null; //暂存私信发往的id
    noMoreRecords = false;
    postList = [];

    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => true,
        });

        this.state = {
            dataSource: dataSource.cloneWithRows({}),
            reply_div_display: "none",
            collect_svg: "collect_svg",
            sold_display:'none',
            collect_display:'block',
            reply_display:'block',
            chat_display:'block',
            page: 1,
        };
    }

    fetchData(url, isNewSearch) {
        this.setState({
            isLoading: true,
        });
        fetchAll(url)
            .then(res => {
                //没取到，表示到最后一页了
                if (
                    res.result === "failed" ||
                    (res.founds && res.founds.length === 0)
                ) {
                    this.noMoreRecords = true;
                    this.setState({
                        isLoading: false,
                        loadEmptyHint:"没找到哎,试试别的吧！",
                    });
                    return;
                }else{
                    this.noMoreRecords = false; //为了到达最后后，又重新刷新
                }

                this.postList = this.postList.concat(res.founds);
                //没取到，表示到最后一页了
                if(res.founds && res.founds.length===0){
                    this.noMoreRecords=true;
                }else{
                    if(isNewSearch){
                        this.props.refreshTitle(res.founds[0].title)
                    }
                }
                this.setState({
                    dataSource: this.state.dataSource.cloneWithRows(this.postList),
                    isLoading: false,
                    page: this.state.page + 1,
                });

            })
            .catch(err => {
                this.noMoreRecords = true;
                this.setState({
                    isLoading: false,
                });
                // console.log(err);
                // Toast.fail(err);
            });
    }


    componentDidMount() {
        this.getPostDetail();
    }

    //退出时保存当前页信息，以便在返回时不重新加载，而是回到上次浏览的状态
    componentWillUnmount() {
        //防止报错：Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application
        this.setState = (state,callback)=>{
            return;
        };
    }

    getPostDetail(){
        let url =
            "/api/v1/posts?post-id="+this.props.postId +
            `&user-id=` +getUserId()+
            "&page=1&size=10";
        this.fetchData(url, true);
    }

    onEndReached = () => {
        //如果已经到达最后一页，不再加载了
        if (this.noMoreRecords) {
            this.setState({ isLoading: false });
            return;
        }

        let url =
            "/api/v1/posts?post-id="+this.props.postId +
            `&page=${this.state.page}` +
            "&size=10";
        this.fetchData(url, false);
    };

    onScroll = () => {
    };

    checkIsLogin(){
        if (!getUserId()) {
            Toast.fail("还没登录呢,请登录后重试", 3, null, false);
            this.props.history.push('/pwdlogin');
            return false;
        }
        return true;
    }

    onDelete= (rowData, e) => {
        let url = "";
        if(rowData.parentPostId){
            url = "/api/v1/posts/" + rowData.parentPostId + "/comments/" + rowData.id;
        }else{
            url = "/api/v1/posts/" + rowData.id;
        }
        fetchAll(url, null,'DELETE')
            .then(responseData => {
                if (responseData.result === "success") {
                    Toast.success("删除成功", 3, null, false);
                    this.postList = [];
                    this.setState({
                        dataSource: this.state.dataSource.cloneWithRows(this.postList),
                    });
                    this.getPostDetail();
                }else {
                    Toast.fail("哎呀，删除失败。请稍后再试！", 3, null, false);
                }
            })
            .catch(err => {
                Toast.fail("删除失败,请稍后再试！", 3, null, false);
            });
    };

    onTag = (rowData, index, e) => {
        let payload = {};
        payload.userId = getUserId();
        let tags = {};
        if(rowData.type && rowData.type === "sell") {
            tags.sold = !(rowData.tags && rowData.tags.sold === true);
        }else if(rowData.type && rowData.type === "buy"){
            tags.bought = !(rowData.tags && rowData.tags.bought === true);
        }else{
            tags.given = !(rowData.tags && rowData.tags.given === true);
        }
        payload.tags = tags;
        fetchAll("/api/v1/posts/" + rowData.id + "/tags", payload, "POST")
            .then(responseData => {
                if (responseData.result === "success"){
                    if(!rowData.tags){
                        rowData.tags = {};
                    }
                    rowData.tags = tags;
                    this.postList[index].tags.sold = tags.sold;
                    this.postList[index].tags.bought = tags.bought;
                    this.postList[index].tags.given = tags.given;
                    this.setState({
                        dataSource: this.state.dataSource.cloneWithRows(this.postList),
                    });
                    Toast.success("已标记", 3, null, false);
                }
                else Toast.fail("哎呀，标记失败。请稍后再试！", 3, null, false);
            })
            .catch(err => {
                Toast.fail("标记失败,请稍后再试！" + err, 3, null, false);
            });
    };

    onCollect = (rowData, index,e) => {
        if(!this.checkIsLogin())
            return;

        let payload = {};
        payload.postOrCommentId = rowData.id;
        payload.userId = getUserId();
        payload.type = "collect";

        let url = "/api/v1/postrefs";
        let method = 'POST';
        if(rowData.collected === true){
            method = 'DELETE';
        }

        fetchAll(url, payload,method)
            .then(responseData => {
                if (responseData.result === 'success') {
                    Toast.success(rowData.collected?"取消收藏成功":"收藏成功", 3, null, false);
                    this.postList[index].collected = !this.postList[index].collected;

                    this.setState({
                        dataSource: this.state.dataSource.cloneWithRows(this.postList),
                    });
                } else {
                    Toast.fail(rowData.collected?"取消收藏失败":"收藏失败", 3, null, false);
                }
            })
            .catch(err => {
                Toast.fail("操作失败，"+err.message, 3, null, false);
            });
    };

    onEdit = (rowData, e) => {
        if(rowData.parentPostId){
            let replyToCommentId = rowData.id;
            let category = rowData.category
            let toPath = `/reply/${rowData.parentPostId}/${replyToCommentId}/${category}`;
            this.props.history.push({pathname: toPath,query:{"editComment": rowData}});
        }else{
            let mainCategory = rowData.category.split(".")[0]
            this.props.history.push({pathname:"/write/" + mainCategory, query:{"editPost": rowData}})
        }
    }

    onReply = (rowData, e) => {
        let parentPostId = rowData.id;
        //主贴没有这个字段，跟帖有
        if (rowData.parentPostId) parentPostId = rowData.parentPostId;
        let replyToCommentId = rowData.id;
        let category = rowData.category
        let toPath = "";
        toPath = `/reply/${parentPostId}/${replyToCommentId}/${category}`;
        this.props.history.push({pathname: toPath});
    };

    onShowChat = (rowData, e) => {
        if(!this.checkIsLogin()) {
            return;
        }
        let accountInfo = getLocalData("accountInfo");
        if(accountInfo && accountInfo.phone && accountInfo.phone !== "") {
            this.setState({
                reply_div_display: "block",
                replyToCommentId: rowData.parentPostId ? rowData.id : null
            });
            this.chatUserId = rowData.userInfo.uid;
        }else{
            this.props.history.push({pathname: "/bind", query: {"tips": "您未绑定手机，绑定之后才能发站内私信"}});
        }
    };

    onChatSubmit = (msg, refPostId, refCommentId) => {
        if (!getUserId()) {
            Toast.fail("您尚未登录,请登录后重试", 3, null, false);
            return;
        }
        if (!this.chatUserId ) {
            Toast.fail("未找到接收方,请稍后重试", 3, null, false);
            return;
        }
        let payload = {};
        payload.sender = getUserId();
        payload.receiver = this.chatUserId;
        payload.title = "title";
        payload.content = msg;
        payload.referPostId = refPostId;
        payload.referCommentId = refCommentId;

        fetchAll("/api/v1/mails/private", payload,'POST')
            .then(responseData => {
                if (responseData.result === "success") {
                    Toast.success("您的信件已发出", 3, null, false);
                }else{
                    Toast.fail(responseData.err, 3, null, false);
                }
                this.setState({
                    reply_div_display: "none",
                });
            })
            .catch(err => {
                Toast.fail("发信失败,请重试", 3, null, false);
            });
    };

    onChatCancel = msg => {
        this.setState({
            reply_div_display: "none",
        });
    };

    render() {
        const _renderSeparator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: "#F5F5F9",
                    height: 10,
                    borderTop: "1px solid #ECECED",
                    borderBottom: "1px solid #ECECED",
                }}
            />
        );
        //记录当前行
        let index=-1;
        const _renderRow = rowData => {
            const picList = [];
            index++;
            // 如果存在图片，放入elements数组
            if (rowData.pictures) {
                picList.push(
                    <PhotoProvider key={rowData.id+""+index}>
                        {rowData.pictures.map((item, index) => (
                            <div>
                                <PhotoConsumer key={rowData.id+""+index} src={item.url} >
                                {/*<PhotoConsumer key={rowData.id+""+index} src={item.url} intro={item.thumbnailUrl}>*/}
                                    <img
                                        src={item.thumbnailUrl}
                                        alt=""
                                        style={{
                                            width: "200px",
                                            margin: "0 auto", //水平居中
                                            marginBottom: "10px",//下方有空
                                        }}
                                    />
                                </PhotoConsumer>
                                <br/>
                            </div>
                        ))}
                    </PhotoProvider>
                );
            }

            return (
                <div key={rowData.id}>
                    {rowData. parentPostId ?
                        <div className="portrait_and_time">
                            <div className={"detail_author_reply"}>
                                <UserPortrait
                                    portraitlUrl = {rowData.userInfo && rowData.userInfo.portraitThumbnailUrl ? rowData.userInfo.portraitThumbnailUrl : null}
                                    displayName = {rowData.userInfo && rowData.userInfo.displayName ? rowData.userInfo.displayName : "住这儿用户"}
                                />
                                <RightOutline/>
                                <UserPortrait
                                    portraitlUrl = {rowData.replyToUserInfo && rowData.replyToUserInfo.portraitThumbnailUrl ? rowData.replyToUserInfo.portraitThumbnailUrl : null}
                                    displayName = {rowData.replyToUserInfo && rowData.replyToUserInfo.displayName ? rowData.replyToUserInfo.displayName : "住这儿用户"}
                                />
                            </div>
                            <span style={{"fontSize":"12px"}}>{formatTime(rowData.createTime,'YYYY-MM-DD hh:mm:ss')}</span>
                        </div>
                        :
                        <div className="portrait_and_time">
                            <UserPortrait
                                portraitlUrl = {rowData.userInfo && rowData.userInfo.portraitThumbnailUrl ? rowData.userInfo.portraitThumbnailUrl : null}
                                displayName = {rowData.userInfo && rowData.userInfo.displayName ? rowData.userInfo.displayName : "住这儿用户"}
                            />
                            <span style={{"fontSize":"12px"}}>{formatTime(rowData.createTime,'YYYY-MM-DD hh:mm:ss')}</span>
                        </div>
                    }
                    {/* 加上whiteSpace:"pre"会有换行，但没遇到换行符不自动换行 */}
                    <div
                        className="post_article"
                        style={{
                            whiteSpace: "pre-wrap",
                            // overflow: "hidden",
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                        }}
                    >
                        {rowData.reference
                            ? <PostDetailReference referenceAbstraction={rowData.reference} replyToUserInfo ={rowData.replyToUserInfo} parentPostId ={rowData.parentPostId} commentId = {rowData.replyToOriginCommentId}/>
                            : <div style={{"display":"none"}}/>
                        }
                        {rowData.content}
                        {rowData.category && rowData.category.startsWith("recycle") ?
                            (
                                rowData.type === "sell" ?
                                    <img src={sold_png} style={{"display":rowData.category && rowData.category.startsWith("recycle") && rowData.tags && rowData.tags.sold === true ? "block" : "none","position": "absolute", "left": "65%", "top": "5px", "zIndex": "999"}}/>
                                    :
                                    (
                                        rowData.type === "buy" ?
                                        <img src={bought_png} style={{"display":rowData.category && rowData.category.startsWith("recycle") && rowData.tags && rowData.tags.bought === true ? "block" : "none","position": "absolute", "left": "65%", "top": "5px", "zIndex": "999"}}/>
                                        :
                                            <img src={sold_png} style={{"display":rowData.category && rowData.category.startsWith("recycle") && rowData.tags && rowData.tags.given === true ? "block" : "none","position": "absolute", "left": "65%", "top": "5px", "zIndex": "999"}}/>
                                    )
                            )
                            :(<div style={{"display":"none"}}/>)
                        }

                    </div>
                    <div style={{ textAlign: "center" }}>{picList}</div>

                    <div className="detail_bottom">
                        <div className="house_svg" style={rowData.resiQuarterInfo ?{display:'block'}:{visibility:'hidden'}}/>
                        <div>{rowData.resiQuarterInfo ? rowData.resiQuarterInfo.name : ""} </div>
                        <div className="detail_remark">
                            <div className={"postref_with_count"}>
                                <div
                                    className={rowData.collected?'collect_svg_checked':'collect_svg'}
                                    style={{display:'block'}}
                                    onClick={this.onCollect.bind(this, rowData,index)}
                                >
                                </div>
                                <div>{rowData.collect ? rowData.collect : 0}</div>
                            </div>
                            <div
                                className={rowData.tags&&(rowData.tags.sold===true || rowData.tags.bought===true || rowData.tags.given===true) ?"sold_svg_checked":"sold_svg" }
                                style={rowData.category && rowData.category.startsWith("recycle") && rowData.userInfo.uid===getUserId()?{display:'block'}:{display:'none'}}
                                onClick={this.onTag.bind(this, rowData,index)}
                            />
                            <div
                                className="chat_svg"
                                style={(rowData.userInfo.uid===getUserId() || !getUserId())?{display:'none'}:{display:'block'}}
                                onClick={this.onShowChat.bind(this, rowData)}
                            />
                            <div
                                className="reply_svg"
                                style={getUserId()?{display:'block'}:{display:'none'}}
                                onClick={this.onReply.bind(this, rowData)}
                            />
                            <div
                                className="edit_svg"
                                style={(getUserId() && rowData.userInfo.uid===getUserId())?{display:'block'}:{display:'none'}}
                                onClick={this.onEdit.bind(this, rowData)}
                            />
                            <div
                                className="delete_svg"
                                style={rowData.userInfo.uid===getUserId()?{display:'block'}:{display:'none'}}
                                onClick={this.onDelete.bind(this, rowData)}
                            />
                        </div>
                    </div>
                    <div
                        className="reply_div"
                        style={{
                            display: this.state.reply_div_display,
                        }}
                    >
                        <Chat
                            onChatSubmit={this.onChatSubmit}
                            onChatCancel={this.onChatCancel}
                            postId={this.props.postId}
                            commentId={this.state.replyToCommentId}
                        />
                    </div>
                </div>
            );
        };

        return (
            <div id="postlist_page">
                {this.postList.length === 0 && this.state.isLoading === true ?
                    (
                        <div style={{ textAlign: "center" }}>
                            加载中<Loading color="currentColor" />
                        </div>
                    ):
                    (
                        this.postList.length === 0 ?
                            (
                                <ErrorBlock description={
                                    <span>
                                        内容迷路了，试试别的吧！
                                    </span>
                                }>
                                </ErrorBlock>
                            )
                            :
                            <ListView
                                dataSource={this.state.dataSource}
                                initialListSize={10}
                                renderFooter={() => (
                                    <div key={"footer"} style={{ padding: 30, textAlign: "center" }}>
                                        {this.state.isLoading ? "加载中..." : "真的没有了哦"}
                                    </div>
                                )}

                                renderRow={_renderRow}
                                renderSeparator={_renderSeparator}
                                style={{
                                    height: document.documentElement.clientHeight,
                                    overflow: "auto",
                                }}
                                pageSize={10}
                                // onScroll={this.onScroll}
                                // onClick={() => {
                                //   console.log("scroclickll");
                                // }}
                                scrollRenderAheadDistance={500}
                                onEndReached={this.onEndReached}
                                onEndReachedThreshold={10}
                            />
                    )}
            </div>
        );
    }
}
export default withRouter(PostDetail);
