import {
    List,
    InputItem,
    Button,
    Flex,
    WingBlank,
    ImagePicker,
    Toast,NavBar,Icon
} from "antd-mobile";
//import {Button} from "antd-mobile-v5";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../../css/setting.less";

import position_png from "../../icon/position.png";
import { fetchBlob } from "../../util/HttpUtil";
import {getLocalData, setLocalData, getUserId, getCurrentUser, getSessionData} from "../../util/commonUtil";
import ImgUtil from "../../util/ImgUtil";
import md5 from "js-md5";


const ListItem = List.Item;
class Setting extends Component {
    accountInfo = null;
    constructor(props) {
        super(props);
        this.state = {
            userName: ""
            , isUserNameSet: false
            , isPasswordSet: false
        };
        this.client = React.createRef();
    }

    componentDidMount() {
        if(getUserId()){
            this.accountInfo = getLocalData("accountInfo");
            this.setState({
                userLogined:true,
                userImg:this.accountInfo.portraitThumbnailUrl,
                displayName:this.accountInfo.displayName,
                userName:this.accountInfo.userName,
                phone:this.accountInfo.phone,
                isUserNameSet: this.accountInfo.userName && this.accountInfo.userName !== "",
                isPasswordSet: this.accountInfo.hasPwd
            })
        } else{
            this.setState({
                userLogined:false,
            })
        }
        let footerHeight = getSessionData("footer_height");
        let mainPage = document.getElementById('root').parentNode
        this.setState({
            login_error_display: "none",
            clientHeight: mainPage.clientHeight - (footerHeight ? footerHeight : 60)
        })
    }

    //添加图片时，进行压缩
    onImgChange = async(files, type, index) => {
        if (files.length === 0) {
            return;
        }
        let fileSize = files[0].file.size / 1024;
        //大于40k的图片进行压缩
        if (fileSize > 40) {
            await ImgUtil.transformFile(files[0].file).then(value => {
                files[0].file = value;
            });
        }

        let formData = new FormData();
        // formData.append("doc",JSON.stringify(payload));
// formData.append("doc", payload);
// let test = new Object(); 
// formData.append("updates", JSON.stringify(test))
        //上传服务器
        // let formData = new FormData();
        formData.append("portrait", files[0].file);
        let userId=this.accountInfo.uid;//"2c420559d39a4fd5a15178e6c445d073";
        let url = "/api/v1/accounts/"+userId;
        await fetchBlob(url, "PATCH", formData)
            .then(responseData => {
                if(responseData.result === "success") {
                    this.setState({
                        userImg: responseData.portraitThumbnailUrl,
                    });
                    //更新本地的用户信息
                    this.accountInfo.portraitThumbnailUrl = responseData.portraitThumbnailUrl;
                    this.accountInfo.portraitUrl = responseData.portraitUrl;
                    setLocalData("accountInfo", this.accountInfo);
                    Toast.success("设置成功", 3, null, false);
                }else{
                    Toast.fail(responseData.err, 3, null, false);
                }
            })
            .catch(err => {
                Toast.fail("上传失败,请稍后重试", 3, null, false);
            });
    };

    //更改显示名称
    changeDisplayName=()=>{
        let payload = {};
        payload.displayName = this.state.displayName;
        let formData = new FormData();
        formData.append("updates",JSON.stringify(payload));
        fetchBlob("/api/v1/accounts/"+getUserId(),"PATCH", formData)
            .then(responseData => {
                if(responseData.result === "success") {
                    this.accountInfo.displayName = this.state.displayName;
                    setLocalData("accountInfo", this.accountInfo);
                    Toast.success("设置成功！", 3, null, false);
                }else{
                    Toast.fail(responseData.err, 3, null, false);
                }
            })
            .catch(err => {
                Toast.fail("设置失败,请稍后重试", 3, null, false);

            });
    }

    //设置用户名账号
    changeUserName=()=>{
        let payload = {};
        payload.userName = this.state.userName;
        let formData = new FormData();
        formData.append("updates",JSON.stringify(payload));
        fetchBlob("/api/v1/accounts/"+getUserId(),"PATCH", formData)
            .then(responseData => {
                if(responseData.result === "success") {
                    this.accountInfo.userName = this.state.userName;
                    setLocalData("accountInfo", this.accountInfo);
                    this.setState({isUserNameSet: true});
                    Toast.success("设置成功！", 3, null, false);
                }else{
                    Toast.fail("设置失败，" + responseData.err);
                }
            })
            .catch(err => {
                Toast.fail("设置失败,请稍后重试", 3, null, false);
            });
    }

    //更改密码
    changePassword =()=>{
        this.setState({
            login_error_display: "none",
        });
        const password1 = this.state.password1;
        const password2 = this.state.password2;
        if (!password1|| !password2 || password1.length === 0 || password2.length === 0) {
            this.setState({
                login_error_display: "block",
                errorMsg:"请输入密码",
            });
            return;
        }
        if (password1!==password2) {
            this.setState({
                login_error_display: "block",
                errorMsg:"两次密码不一致，请确认",
            });
            return;
        }

        let payload = {};
        payload.password = md5(this.state.password1);
        let formData = new FormData();
        formData.append("updates",JSON.stringify(payload));

        fetchBlob("/api/v1/accounts/"+getUserId(),"PATCH", formData)
            .then(responseData => {
                if(responseData.result === "success") {
                    this.accountInfo.hasPwd = true;
                    setLocalData("accountInfo",this.accountInfo);
                    this.setState({isPasswordSet: true, password1: "", password2: ""});
                    Toast.success("设置成功！", 3, null, false);
                }else{
                    this.setState({password1: "", password2: ""});
                    Toast.fail("设置失败，" + responseData.err, 3, null, false);
                }
            })
            .catch(err => {
                this.setState({password1: "", password2: ""});
                Toast.fail("设置失败,请稍后重试", 3, null, false);
            });
    }

    render() {
        const avatorImg = () => {
            return (
                <img className="user-avatar" src={this.state.userImg} alt="" />
            );
        };
        return (
            <div style={{height: this.state.clientHeight + "px", overflow:"scroll"}} ref={this.client}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        require("history").createHashHistory().goBack();
                    }}
                >
                    设置
                </NavBar>
                <div>
                    <List renderHeader={() => "设置您所在小区"} style={{"headerFontSize": "10px"}}>
                        <ListItem thumb={position_png} arrow="horizontal" onClick={() => {this.props.history.push('/setArea');}} extra="修改">
                            {getCurrentUser().resiQuarterInfo?getCurrentUser().resiQuarterInfo.name:'设置小区'}
                        </ListItem>
                    </List>
                    <List renderHeader={() => "点击更换头像"}>
                        <ListItem>
                            {/* 头像和imagepicker组件位置重合一起 */}
                            <WingBlank className="user-avatar-upload">
                                {avatorImg()}
                                <ImagePicker
                                    onChange={this.onImgChange}
                                    accept="image/gif,image/jpeg,image/jpg,image/png"
                                />{" "}
                            </WingBlank>
                        </ListItem>
                    </List>
                    <List renderHeader={() => "手机" + (this.state.phone ? " 已绑定" : " 未绑定")} >
                        {
                            this.state.phone && this.state.phone !== ""
                                ?
                                <ListItem>
                                    {this.state.phone}
                                </ListItem>
                                :
                                <ListItem onClick={() => {this.props.history.push({pathname: "/bind", query: {"tips": ""}});}} extra="绑定"  arrow="horizontal">
                                    {"未绑定手机将无法进行发文"}
                                </ListItem>
                        }

                    </List>
                    <List renderHeader={() => "账号" + (this.state.isUserNameSet ? "" : " 大小写字母、数字(不能用作开头)，6-16字符")}>
                        <InputItem
                            clear
                            maxLength={16}
                            value={this.state.userName}
                            onChange={userName => {
                                this.setState({ userName });
                            }}
                            // disabled={this.state.isUserNameSet}
                            editable={!this.state.isUserNameSet}
                            placeholder={"设置唯一账号作为用户名"}
                        >
                            用户名
                        </InputItem>
                        <Flex style={{ padding: "5px 35px", display: (this.state.isUserNameSet ? "none" : "block") }}>
                            <Flex.Item>
                                <Button type="primary" onClick={this.changeUserName} >
                                    设置账号
                                </Button>
                            </Flex.Item>
                        </Flex>
                    </List>
                    <List renderHeader={() => "修改显示名称"}>
                        <InputItem
                            clear
                            maxLength={12}
                            value={this.state.displayName}
                            onChange={displayName => {
                                this.setState({ displayName });
                            }}
                        >
                            新名称
                        </InputItem>
                        <Flex style={{ padding: "5px 35px" }}>
                            <Flex.Item>
                                <Button type="primary" onClick={this.changeDisplayName} >
                                    修改显示名称
                                </Button>
                            </Flex.Item>
                        </Flex>
                    </List>
                    <List renderHeader={() => this.state.isPasswordSet ? "修改密码" : "密登录码未设置，请及时设置"}>
                        <InputItem
                            clear
                            value={this.state.password1}
                            onChange={password1 => {
                                this.setState({ password1 });
                            }}
                            type="password"
                        >
                            新密码
                        </InputItem>
                        <InputItem
                            clear
                            value={this.state.password2}
                            onChange={password2 => {
                                this.setState({ password2 });
                            }}
                            type="password"
                        >
                            确认密码
                        </InputItem>
                    </List>
                    <Flex
                        style={{
                            padding: "15px",
                            color: "#FF0000",
                            display: this.state.login_error_display,
                        }}
                    >
                        <Flex.Item>{this.state.errorMsg} </Flex.Item>
                    </Flex>
                    <Flex style={{ padding: "5px 35px" }}>
                        <Flex.Item>
                            <Button type="primary"   onClick={this.changePassword} >
                                修改密码
                            </Button>
                        </Flex.Item>
                    </Flex>
                </div>
            </div>
        );
    }
}

export default withRouter(Setting);
