//检查是否为图片类型
export function checkIsImage(fileName) {
  let flag;
  let imageTypeList = [
    ".jpg",
    ".png",
    ".jpeg",
    ".gif",
    ".GIF",
    ".JPG",
    ".PNG",
    ".JPEG",
  ];
  for (let item of imageTypeList) {
    flag = fileName.indexOf(item) !== -1;
    if (flag) {
      return true;
    }
  }
  return false;
}

export default class ImgUtil{
    //压缩图片
    static transformFile(file,props) {

      //判断是否是图片类型
      if (checkIsImage(file.name)) {
        // const {
            const  compressThreshold = 5;
            const isPictureCompress = false;
            const pictureQuality = 0.8;
        // } = props;
        // let fileSize = file.size / 1024 / 1024;
        // console.log('before compress, the file size is : ', fileSize + "M");
        //当开启图片压缩且图片大小大于等于压缩阈值,进行压缩
        //   if ((fileSize >= compressThreshold) && isPictureCompress) {

        if (true) {
          //判断浏览器内核是否支持base64图片压缩
          if (typeof FileReader === "undefined") {
            return file;
          } else {
            try {
              return new Promise(resolve => {
                //声明FileReader文件读取对象
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  // 生成canvas画布
                  const canvas = document.createElement("canvas");
                  // 生成img
                  const img = document.createElement("img");
                  img.src = reader.result;
                  img.onload = () => {
                    const ctx = canvas.getContext("2d");
                    //原始图片宽度、高度
                    let originImageWidth = img.width,
                      originImageHeight = img.height;
                    //默认最大尺度的尺寸限制在（1920 * 1080）
                    let maxWidth = 960,//1920,
                      maxHeight = 1280,//1080,
                      ratio = maxWidth / maxHeight;
                    //目标尺寸
                    let targetWidth = originImageWidth,
                      targetHeight = originImageHeight;
                    //当图片的宽度或者高度大于指定的最大宽度或者最大高度时,进行缩放图片
                    if (
                      originImageWidth > maxWidth ||
                      originImageHeight > maxHeight
                    ) {
                      //超过最大宽高比例
                      if (originImageWidth / originImageHeight > ratio) {
                        //宽度取最大宽度值maxWidth,缩放高度
                        targetWidth = maxWidth;
                        targetHeight = Math.round(
                          maxWidth * (originImageHeight / originImageWidth)
                        );
                      } else {
                        //高度取最大高度值maxHeight,缩放宽度
                        targetHeight = maxHeight;
                        targetWidth = Math.round(
                          maxHeight * (originImageWidth / originImageHeight)
                        );
                      }
                    }
                    // canvas对图片进行缩放
                    canvas.width = targetWidth;
                    canvas.height = targetHeight;
                    // 清除画布
                    ctx.clearRect(0, 0, targetWidth, targetHeight);
                    // 绘制图片
                    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
                    // quality值越小,图像越模糊,默认图片质量为0.92
                    const imageDataURL = canvas.toDataURL(
                      file.type || "image/jpeg",
                      pictureQuality
                    );
                    // 去掉URL的头,并转换为byte
                    const imageBytes = window.atob(imageDataURL.split(",")[1]);
                    // 处理异常,将ascii码小于0的转换为大于0
                    const arrayBuffer = new ArrayBuffer(imageBytes.length);
                    const uint8Array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < imageBytes.length; i++) {
                      uint8Array[i] = imageBytes.charCodeAt(i);
                    }
                    let mimeType = imageDataURL.split(",")[0].match(/:(.*?);/)[1];
                    let newFile = new File([uint8Array], file.name, {
                      type: mimeType || "image/jpeg",
                    });
                    // console.log('after compress, the file size is : ', (newFile.size / 1024 / 1024) + "M");
                    resolve(newFile);
                  };
                };
                reader.onerror = () => {
                  return file;
                };
              })
                .then(res => {
                  return res;
                })
                .catch(e => {
                  console.log(e);
                  return file;
                });
            } catch (e) {
              console.log(e);
              //压缩出错,直接返回原file对象
              return file;
            }
          }
        } else {
          //不需要压缩，直接返回原file对象
          return file;
        }
      } else {
        //非图片文件,不进行压缩,直接返回原file对象
        return file;
      }
    }
  } 