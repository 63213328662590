import {List, InputItem, Button, Flex, Toast, NavBar, Icon} from "antd-mobile";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {fetchAll} from "../../util/HttpUtil";
import md5 from "js-md5";
import throttle from 'lodash/throttle';


class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            password1: "",
            password2: "",
            rediectToReferrer: false, // 是否重定向之前的界面
            login_error_display:'none',
        };

        this.handleSumit = throttle (this.handleSumit, 2000);
    }


    // 提交登录表单
    handleSumit = async(e) => {
        // e.preventDefault();
        this.setState({
            login_error_display: "none",
        });
        const userName = this.state.userName;
        const password1 = this.state.password1;
        const password2 = this.state.password2;
        if (userName.length === 0 ) {
            this.setState({
                login_error_display: "block",
                errorMsg:"请输入用户名",
            });
            return;
        }
        if (password1.length === 0 || password2.length === 0) {
            this.setState({
                login_error_display: "block",
                errorMsg:"请输入密码",
            });
            return;
        }
        if (password1!==password2) {
            this.setState({
                login_error_display: "block",
                errorMsg:"两次密码不一致，请确认",
            });
            return;
        }

        let params = {};
        params.userName = userName;
        params.password = md5(password1);
        // let token=null;
        let registerFlag = false;
        let err = "";
        await fetchAll("/api/v1/accounts", params,'POST')
            .then(responseData => {
                registerFlag=responseData.result;
                err = responseData.err;
            })
            .catch(err => {
                console.log(err);
                Toast.fail("遇到点问题，请稍后重试", 3, null, false);
                return; //这里return，对后面语句的执行不起作用，还会执行
            });
        if('success'!==registerFlag){
            Toast.fail(err, 3, null, false);
            return;
        }else{
            Toast.success("恭喜注册成功！精彩即刻呈现…", 3, null, false);
        }
        // await fetchAll("/api/v1/login", params,'POST')
        //     .then(responseData => {
        //         let {token,accountInfo} = {...responseData};
        //
        //         setLocalData("lastserName", userName);//用于登录时默认设置上次登录的用户
        //         setLocalData("token",token);
        //         setLocalData("accountInfo",accountInfo);
        //
        //         // const redirectHref = sessionStorage.getItem("redirectHref");
        //
        //         //   let prevRouter=this.props.match.params.prevRouter;
        //         // let redirectUrl = prevRouter
        //         //   ? "/"+prevRouter
        //         //   : "/my";
        //         let redirectUrl='/my';
        //         // if(redirectHref&&redirectHref.includes('/#/')&&redirectHref.split('/#/')[1]){
        //         //   redirectUrl='/'+redirectHref.split('/#/')[1]||'/my';
        //         // }
        //         // 登陆成功之后的跳转,用replace而非push，清除/login的历史，以免后续返回的时候回到登录页
        //         this.props.history.replace(redirectUrl);
        //
        //     })
        //     .catch(err => {
        //         // const msg = response.msg;
        //         console.log(err);
        //         Toast.fail("登录失败，请重试");
        //         return;
        //     });


    };

    filterName = (value = "") => {
        return value.trim().replace(/[^a-zA-Z0-9~!@#$%^&*()_\-+=<>?:"{}|,./]/g,"");
    };

    render() {
        return (
            <div>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        require("history").createHashHistory().goBack();
                    }}
                >
                    注册账号
                </NavBar>
                <List renderHeader={() => "欢迎注册,畅享更多服务"}>
                    <InputItem
                        clear
                        maxLength={15}
                        value={this.state.userName}
                        onChange={userName => {
                            this.setState({ userName:this.filterName(userName).trim() });
                        }}
                    >
                        用户名
                    </InputItem>
                    <InputItem
                        clear
                        value={this.state.password1}
                        onChange={password1 => {
                            this.setState({ password1 });
                        }}
                        type="password"
                    >
                        密码
                    </InputItem>
                    <InputItem
                        clear
                        value={this.state.password2}
                        onChange={password2 => {
                            this.setState({ password2 });
                        }}
                        type="password"
                    >
                        确认密码
                    </InputItem>
                </List>


                <Flex style={{ padding: '15px' ,color: "#FF0000" ,display: this.state.login_error_display }}>
                    <Flex.Item>
                        {this.state.errorMsg}
                    </Flex.Item>
                </Flex>

                <Flex style={{ padding: '35px' }}>
                    <Flex.Item>
                        <Button type="primary" onClick={this.handleSumit}>
                            注册
                        </Button>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}

export default withRouter(Register);
