import { getRefreshTokenParam, getLocalData, setLocalData } from "./commonUtil";

export default class HttpUtil {
  static get = url => {
    return new Promise((resolve, reject) => {
      //resolve 和 reject 函数被调用时，分别将promise的状态改为fulfilled（完成）或rejected（失败）
      fetch(url) //默认是GET
        .then(response => response.json()) //把数据解析成json格式,然后取出
        // .then(result=>{
        //     console.log("111");
        //     console.log(result);
        //     console.log("222");
        // })
        .then(result => {
          resolve(result); //表示完成
        })
        .catch(error => {
          reject(error); //表示失败
        });
    });
  };

  static post = (url, data) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json", //告诉服务器，我们能接受json格式的返回类型，
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), //(把你想提交得数据序列化为json字符串类型，然后提交)body中的数据就是我们需要向服务器提交的数据,比如用户名,密码等
      }) //返回 服务器处理的结果
        .then(response => response.json())
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  static checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  };

  static getNewAccessToken = async () => {
    let response = await fetch("/api/v1/auth/token", {
      method: "POST",
      headers: {
        Accept: "application/json", //告诉服务器，我们能接受json格式的返回类型，
        "Content-Type": "application/json",
      },
      body: JSON.stringify(getRefreshTokenParam()),
    });
    if (response.status >= 200 && response.status < 300) {
      return await response.json();
    } else {
      sessionStorage.clear(); //清空本地用户登录信息
      localStorage.clear();
      throw new Error("登录已过期，请重新登录");
    }
  };

  static postFile = (url, formData) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        headers: {},
        body: formData,
      }) //返回 服务器处理的结果 返回字符串
        .then(HttpUtil.checkStatus())
        .then(response => response.text)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
} //数据转换成字符串 JSON.stringify(params)      //将数据JSON化 JSON.parse(responseJSON)

async function retryFetchBlob(url, method, formData) {
    
  let response = await fetch(url, {
    method: method,
    headers: {
      token: getLocalData("token"),
    },
    body: formData,
  });

  if (response.status >= 200 && response.status < 300) {
    return await response.json();
  }else if (response.status === 401) {
        throw new Error("登录已过期，请重新登录");
  } else {
    throw new Error(response.statusText);
  }
}

export async function fetchBlob(url, method, formData) {
  let response = await fetch(url, {
    method: method,
    headers: {
      token: getLocalData("token"),
      //'Content-Type': 'multipart/form-data; boundary=xxxxxxxx'
    },
    body: formData,
  });

  if (response.status >= 200 && response.status < 300) {
    return await response.json();
  }else if (response.status === 401) {
    let resp = await response.json();
    //刷新token
    if (resp.code === -10002) {
      try {
        let accessToken = await HttpUtil.getNewAccessToken();
        setLocalData("token", accessToken.token);
        return retryFetchBlob(url, method, formData); //重发请求
      } catch (err) {
        throw new Error("登录已过期，请重新登录");
      }
    }
  } else {
    throw new Error(response.statusText);
  }
}

async function retryFetchAll(url, data, method = "GET") {
    let params = {
        method: method,
        headers: {
          Accept: "application/json", //告诉服务器，我们能接受json格式的返回类型，
          "Content-Type": "application/json",
          token: getLocalData("token"),
        }};
      if(method !== "GET"){
        params.body=JSON.stringify(data);
      }
    let response = await fetch(url,params );
    if (response.status >= 200 && response.status < 300) {
      return await response.json();
    } else if (response.status === 401) {
          throw new Error("登录已过期，请重新登录");
    } else {
      throw new Error(response.statusText);
    }
  }

export async function fetchAll(url, data = null, method = "GET", acc = "application/json") {
    let params = {
        method: method,
        headers: {
          Accept: acc, //告诉服务器，我们能接受json格式的返回类型，
          "Content-Type": "application/json",
          token: getLocalData("token"),
        }};
      if(method !== "GET"){
        params.body=JSON.stringify(data);
      }
  let response = await fetch(url,params );
  if (response.status >= 200 && response.status < 300) {
    if(acc === "application/json") {
      return await response.json();
    }else{
      return await response.text();
    }
  } else if (response.status === 401) {
    let resp = await response.json();
    //刷新token
    if (resp.code === -10002) {
      try {
        let accessToken = await HttpUtil.getNewAccessToken();
        setLocalData("token", accessToken.token);
        return retryFetchAll(url, data, method); //重发请求
      } catch (err) {
        throw new Error("登录已过期，请重新登录");
      }
    }
  } else {
    throw new Error(response.statusText);
  }
}
