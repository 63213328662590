import React, { Component } from "react";
import ReviewDetail from "../../components/ReviewDetail.js";
import DetailHeader from "../../components/DetailHeader.js";
import { withRouter } from 'react-router-dom';


class ReviewDetailPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
        };
    }

    refreshTitle = (title) =>{
        this.setState({
            title:title
        });
    }

    render() {
        return (
            // this.postList.length >0 ?
            <div>
                <DetailHeader title={this.state.title ? this.state.title : ""}/>
                <ReviewDetail
                    postOrCommentId={this.props.match.params.postOrCommentId}
                    refreshTitle={this.refreshTitle}
                />
            </div>
        );
    }
}
export default withRouter(ReviewDetailPage); 