import {Button, Checkbox, Flex, Icon, InputItem, List, Modal, NavBar, Toast} from "antd-mobile";
import {Space} from "antd-mobile-v5";
import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {fetchAll} from "../../util/HttpUtil";
import {getLocalData, setLocalData} from "../../util/commonUtil";
import md5 from "js-md5";
import throttle from 'lodash/throttle';
import {getCityMapByAdCode} from "../../util/LocationUtil";

class PasswordLoginPage extends Component {
    constructor(props) {
        super(props);
        let savedUserName = "";
        if(getLocalData("lastUserName")){
            savedUserName=getLocalData("lastUserName") + "";//如果为手机号，会变成整数类型
        }
        this.state = {
            userName: savedUserName,
            password: "",
            rediectToReferrer: false, // 是否重定向之前的界面
            login_error_display:'none',
            policyRead: false,
            showPolicy: false,
            policyText: "test"
        };
        // this.handleChange = this.handleChange.bind(this);
        this.onLoginButtonClicked = throttle (this.onLoginButtonClicked, 2000);
    }


    onPolicyCheck = (e) => {
        this.setState({policyRead: e.target.checked})
    }

    showPolicyDetail = (e) => {
        fetchAll("/api/v1/policy", null, "GET", "text/html")
            .then(responseData => {
                this.setState({policyText: responseData})
            })
            .catch(err => {
                this.setState({policyText: "加载失败，请稍候重试"})
            });
        this.setState({showPolicy: true})
    }

    onPolicyDetailClose =  () => {
        this.setState({
            showPolicy: false,
        });
    }

    // 提交登录表单
    onLoginButtonClicked = (e) => {
        if(!this.state.policyRead){
            Toast.info("登录前请阅读使用协议和个人信息保护政策", 3, null, false);
            return;
        }
        // e.preventDefault();
        const userName = this.state.userName;
        const password = this.state.password;
        if (userName.length === 0 || password.length === 0) {
            this.setState({
                login_error_display: "block",
                password:"",
            });
            return;
        }

        let params = {};
        let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (reg.test(userName)) {
            params.phone = userName;//TODO 加密传输
        }else{
            params.userName = userName;
        }
        params.password = md5(password);

        Promise.race([
            fetchAll("/api/v1/login", params,'POST'),
            new Promise(function(resolve,reject){
                setTimeout(()=> reject(new Error('request timeout')),10000)  //超时
            })])
            .then(responseData => {
                if(responseData.result!=='success'){
                    Toast.fail(responseData.err, 3, null, false);
                    return;
                }
                let {token,accountInfo,refreshToken} = {...responseData};

                setLocalData("lastUserName", userName);//用于登录时默认设置上次登录的用户
                setLocalData("token",token);
                setLocalData("refreshToken",refreshToken);
                setLocalData("accountInfo",accountInfo);
                if(accountInfo.resiQuarterInfo && accountInfo.resiQuarterInfo.cityAdCode) {
                    setLocalData("current_view_city", getCityMapByAdCode()[accountInfo.resiQuarterInfo.cityAdCode]);
                }
                const redirectHref = sessionStorage.getItem("redirectHref");
                let redirectUrl='/my';
                if(redirectHref){
                    if(redirectHref.includes('/#/')&&redirectHref.split('/#/')[1]){
                        redirectUrl='/'+redirectHref.split('/#/')[1]||'/my';
                    }else{
                        redirectUrl = redirectHref
                    }
                }
                let redirectQuery = sessionStorage.getItem("redirectQuery");
                if(redirectQuery && redirectQuery !== "" && redirectQuery !== "undefined"){
                    redirectQuery = JSON.parse(redirectQuery);
                }else{
                    redirectQuery = {}
                }
                sessionStorage.removeItem("redirectHref");
                sessionStorage.removeItem("redirectQuery");
                // 登陆成功之后的跳转,用replace而非push，清除/login的历史，以免后续返回的时候回到登录页
                this.props.history.replace({pathname:redirectUrl, query: redirectQuery});

            })
            .catch(err => {
                Toast.fail("遇到点问题，请先歇歇，稍后再试", 3, null, false);
            });
    };

    filterName = (value = "") => {
        return value.trim().replace(/[^a-zA-Z0-9~!@#$%^&*()_\-+=<>?:"{}|,./]/g,"");
    };

    render() {
        return (
            <div>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        require("history").createHashHistory().goBack();
                    }}
                >
                    登录
                </NavBar>
                <List renderHeader={() => "密码登录"}>
                    <InputItem
                        clear
                        maxLength={16}
                        value={this.state.userName}
                        onChange={userName => {
                            this.setState({ userName:this.filterName(userName).trim() });
                        }}
                        placeholder={"用户名/手机号"}
                    >
                        账号
                    </InputItem>
                    <InputItem
                        clear
                        value={this.state.password}
                        onChange={password => {
                            this.setState({ password });
                        }}
                        type="password"
                    >
                        密码
                    </InputItem>
                </List>
                <Flex style={{ padding: '15px' ,color: "#ff0000" ,display: this.state.login_error_display }}>
                    <Flex.Item>
                        用户名或密码错误，请重试
                    </Flex.Item>
                </Flex>
                <Flex style={{ padding: '35px' }}>
                    <Flex.Item>
                        <Button type="primary" onClick={this.onLoginButtonClicked} disabled={this.state.userName.trim().length === 0 || this.state.password.length === 0}>
                            登录
                        </Button>
                    </Flex.Item>
                </Flex>
                <Flex style={{ "padding": "15px", "text-align-last": "center"}}>
                    <Flex.Item>
                        <Space>
                            <Checkbox checked={this.state.policyRead} onChange={this.onPolicyCheck}/>
                            <div>已阅读并同意</div>
                            <div onClick={this.showPolicyDetail} style={{"color": "#1890ff"}}>住这儿使用协议、个人信息保护政策</div>
                        </Space>
                    </Flex.Item>
                </Flex>
                <Flex style={{ "padding": "15px", "text-align-last": "center"}}>
                    <Flex.Item>
                        <Link to={'/vcodelogin'} replace={false}>手机验证码登录</Link>
                    </Flex.Item>
                </Flex>
                {/*<Flex style={{ "padding": "15px", "text-align-last": "center"}}>*/}
                {/*    <Flex.Item>*/}
                {/*        <Link replace to={'/register'}>还没有账号？点此立即注册 </Link>*/}
                {/*    </Flex.Item>*/}
                {/*</Flex>*/}

                <Modal style={{"width": "90%","height": "80%" }}
                    visible={this.state.showPolicy}
                    transparent
                    closable
                    onClose={this.onPolicyDetailClose}
                    title="欢迎注册住这儿"
                >
                    <div dangerouslySetInnerHTML={{
                        __html: `${this.state.policyText}`,
                    }}>
                    </div>
                </Modal>
            </div>

        );
    }
}

export default withRouter(PasswordLoginPage);
