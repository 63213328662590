import {InputItem, Button, ImagePicker, List, Flex, Toast, NavBar, Icon} from "antd-mobile";
import {TextArea} from "antd-mobile-v5";
import React, { Component } from "react";
import {fetchAll, fetchBlob} from "../../util/HttpUtil";
import {getLocalData,getCurrentUser} from "../../util/commonUtil";
import ImgUtil from "../../util/ImgUtil";
import {ImageViewer} from "antd-mobile-v5";

import position_png from "../../icon/position.png";
import "@/css/write.css";

const Item = List.Item;

export default class WriteMainPostPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postTitle: "",
            postArticle: "",
            pictureFiles: [],
            select_category_id: "select_category",
            select_type: "select_type",
        };
    }

    componentDidMount() {
        let accountInfo = getLocalData("accountInfo");
        if(accountInfo && accountInfo.phone && accountInfo.phone !== ""){
            this.getCategories();
        }else{
            //跳转登录后回跳
            setTimeout(this.redirectToBindPage, 1)
        }
        if(this.isEdit()){
            let pics = []
            if(this.props.location.query.editPost.pictures){
                pics = this.props.location.query.editPost.pictures
            }
            this.setState(
                {pictureFiles: pics
                , postTitle: this.props.location.query.editPost.title
                , postArticle: this.props.location.query.editPost.content
                , select_category_id: this.props.location.query.editPost.category
                , select_type: this.props.location.query.editPost.type
                }
            )
        }
    }

    redirectToBindPage = ()=>{
        this.props.history.push({pathname:"/bind", query:{"tips": "您未绑定手机，绑定之后才能发文"}})
    }

    getCategories() {
        fetchAll("/api/v1/categories")
            .then(res => {
                let mainCategory = this.props.match.params.category;//从path中的变量获取 category
                for(var i = 0; i < res.categories.length; i++){
                    const c = res.categories[i];
                    if(c["id"] === mainCategory){
                        let categories = c["subCategories"]
                        let category_selector = document.getElementById("category_selector")
                        category_selector.childNodes.forEach((node) => (category_selector.removeChild(node)))
                        category_selector.appendChild(new Option("请选择一个分类", "select_category"))
                        categories.map((item, index) =>(
                                category_selector.appendChild(new Option(item.name, item.id
                                    , this.isEdit() && this.props.location.query.editPost.category === item.id
                                    , this.isEdit() && this.props.location.query.editPost.category === item.id))
                            )
                        )
                        break;
                    }
                }
            })
            .catch(err => {
                this.postList = [];
                this.setState({
                    isLoading: false,
                });
            });
    }

    isEdit(){
        return this.props.location.query && this.props.location.query.editPost
    }

    //添加图片时，进行压缩
    onImgChange = (pictureFiles, type, index) => {
        //重复对所有图片进行遍历，未压缩的进行压缩，以适应一次上传多个图片的情况
        if(type === 'add') {
            if (pictureFiles.length > 9) {
                Toast.info("最多上传9张图片", 3, null, false);
                pictureFiles = pictureFiles.slice(0, 9)
            }
            pictureFiles.map((file, index) => {
                file.orientation = 1 //保持朝向，不设置的话，框架会自动旋转以满足宽>高，导致图片被左或右旋90度
            });
            for (let i = 0; i < pictureFiles.length; i++) {
                if(!pictureFiles[i].file){
                    continue;//编辑模式，图片是原贴中的url
                }
                let fileSize = pictureFiles[i].file.size / 1024;
                //大于1024k的图片进行压缩
                if (fileSize > 1024) {
                    ImgUtil.transformFile(pictureFiles[i].file).then(value => {
                        pictureFiles[i].file = value;
                    });
                }
            }
        }
        this.setState({
            pictureFiles: pictureFiles,
        });
    };

    onImgClicked = (index, pictureFiles) => {
        let file = pictureFiles[index];
        ImageViewer.show({image: file.url})
    }

    onPublish = e => {
        if(this.isEdit()){
            this.editPost()
        }else{
            this.createPost()
        }
    };

    createPost(){
        let pictureFiles = this.state.pictureFiles;
        let payload = {};
        let currentUser = getCurrentUser();
        if(!currentUser || !getLocalData("token")){
            Toast.fail("登录已过期，请重新登录", 3, null, false);
            return;
        }

        payload.resiQuarterId=currentUser.resiQuarterId;
        payload.title=this.state.postTitle;
        payload.content=this.state.postArticle;

        if(!payload.content){
            Toast.fail('请输入内容', 3, null, false);
            return;
        }

        if(this.state.select_category_id === "select_category"){
            Toast.fail('请选择一个分类', 3, null, false);
            return;
        }

        if(this.props.match.params.category === "recycle" && this.state.select_type === "select_type"){
            Toast.fail('请选择漂流类型', 3, null, false);
            return;
        }else {
            payload.type = this.state.select_type;
        }

        if(payload.resiQuarterId === null){
            Toast.fail('请选择您所在的小区', 3, null, false);
            return;
        }
        payload.category = this.state.select_category_id;

        let formData = new FormData();
        formData.append("doc",JSON.stringify(payload));

        for (let i = 0; i < pictureFiles.length; i++) {
            formData.append("pic", pictureFiles[i].file);
        }

        this.props.history.goBack();//不可以，如果从跳转登录返回的这样会重复跳转--login已修正
        fetchBlob("/api/v1/posts", "POST",formData)
            .then(responseData => {
                if(responseData.result === "success") {
                    Toast.success("提交成功，内容将进入审核。审核通过后会自动发布。", 3, null, false);
                }else{
                    Toast.fail(responseData.err, 3, null, false);
                }
            })
            .catch(err => {
                Toast.fail("发文失败,请稍后重试", 3, null, false)
            });
    }

    editPost(){
        let pictureFiles = this.state.pictureFiles;
        let payload = {};
        let currentUser = getCurrentUser();
        if(!currentUser || !getLocalData("token")){
            Toast.fail("登录已过期，请重新登录", 3, null, false);
            return;
        }

        payload.id=this.props.location.query.editPost.id;
        payload.resiQuarterId=this.props.location.query.editPost.resiQuarterInfo.id;
        payload.title=this.state.postTitle;
        payload.content=this.state.postArticle;

        if(!payload.content){
            Toast.fail('请输入内容', 3, null, false);
            return;
        }

        if(this.state.select_category_id === "select_category"){
            Toast.fail('请选择一个分类', 3, null, false);
            return;
        }

        if(this.props.match.params.category === "recycle" && this.state.select_type === "select_type"){
            Toast.fail('请选择漂流类型', 3, null, false);
            return;
        }else {
            payload.type = this.state.select_type;
        }

        if(payload.resiQuarterId === null){
            Toast.fail('请选择您所在的小区', 3, null, false);
            return;
        }
        payload.category = this.state.select_category_id;

        let formData = new FormData();

        let oldPics = []
        for (let i = 0; i < pictureFiles.length; i++) {
            if(pictureFiles[i].file){
                formData.append("pic", pictureFiles[i].file);
            }else{
                oldPics.push(pictureFiles[i])
            }
        }
        payload.pictures = oldPics;
        formData.append("doc",JSON.stringify(payload));

        this.props.history.goBack();//不可以，如果从跳转登录返回的这样会重复跳转--login已修正
        fetchBlob("/api/v1/posts", "PATCH",formData)
            .then(responseData => {
                if(responseData.result === "success") {
                    Toast.success("提交成功，内容将进入审核。审核通过后会自动发布。", 3, null, false);
                }else{
                    Toast.fail(responseData.err, 3, null, false);
                }
            })
            .catch(err => {
                Toast.fail("发文失败,请稍后重试", 3, null, false)
            });
    }

    onCategorySelected = (e) => {
        this.setState({select_category_id: e.target.value})
    };

    onTypeSelected = (e) => {
        this.setState({select_type: e.target.value})
    };

    render() {
        let screenWidth = document.querySelector('body').offsetWidth
        let screenHeight = document.querySelector('body').offsetHeight
        let inputTextRows = 5
        let imagePickerHeight = screenHeight*0.3 + "px"
        let heightWidthRate = screenHeight/screenWidth
        if(heightWidthRate > 1.5){
            imagePickerHeight = screenHeight*0.3 + "px"
            inputTextRows = 5
        }else if(heightWidthRate > 1.3){
            imagePickerHeight = screenHeight*0.2 + "px"
            inputTextRows = 4
        }else{
            imagePickerHeight = screenHeight*0.125 + "px"
            inputTextRows = 3
        }
        return (
            <div className="write_div">
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        require("history").createHashHistory().goBack();
                    }}
                >
                    发布信息
                </NavBar>
                <div style={{
                    display: "block"
                }}>
                    <InputItem
                        id="title_input"
                        clear
                        placeholder="请输入标题"
                        defaultValue={this.isEdit() ? this.props.location.query.editPost.title : ""}
                        maxLength={15}
                        // ref={input => this.postTitle = input}
                        onChange={postTitle => {
                            this.setState({ postTitle });
                        }}
                    />
                </div>
                <div>
                    <select id="category_selector" onChange={this.onCategorySelected}/>
                    <select id="type_selector" onChange={this.onTypeSelected}
                        style={this.props.match.params.category === "recycle" ? {"display" : "table-cell"} : {"display":"none"}}
                        defaultValue={this.isEdit() && this.props.location.query.editPost.type ? this.props.location.query.editPost.type : "select_type"}
                    >
                        <option value={"select_type"}>请选择漂流类型</option>
                        <option value={"sell"}>出售</option>
                        <option value={"buy"}>求购</option>
                        <option value={"give"}>赠送</option>
                    </select>
                </div>
                <br/>
                <TextArea
                    id="article_input"
                    placeholder="请输入详细描述，更快传递信息"
                    defaultValue={this.isEdit() ? this.props.location.query.editPost.content : ""}
                    rows={inputTextRows}
                    maxLength={500}
                    showCount
                    ref={input => (this.postArticle = input)}
                    onChange={postArticle => {
                        this.setState({ postArticle });
                    }}
                />

                <div style={{"width":"auto","height":imagePickerHeight,"overflow":"auto"}}>
                    <ImagePicker
                        length={3}
                        multiple={true}
                        onChange={this.onImgChange}
                        onImageClick={this.onImgClicked}
                        files={this.state.pictureFiles}
                        selectable={this.state.pictureFiles.length < 9}
                    />
                </div>

                <List>
                    <Item thumb={position_png} arrow="horizontal" onClick={() => {this.props.history.push('/setArea');}} extra="修改">
                        {getCurrentUser()&&getCurrentUser().resiQuarterInfo? getCurrentUser().resiQuarterInfo.name:"选择所在位置"}
                    </Item>
                </List>
                <br/>
                <Flex style={{ padding: "5px" }}>
                    <Flex.Item >
                        <Button type="primary" onClick={this.onPublish}>
                            发布
                        </Button>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}
