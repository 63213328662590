import {ListView, Result, Toast, NavBar, Icon, PullToRefresh} from "antd-mobile";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { setLocalData,getLocalData,formatTime } from "@/util/commonUtil";
import {fetchAll} from "@/util/HttpUtil";

import "./msg.css"

export default class MsgList extends Component {
    postList = [];
    scrollTop = 0;
    totalPage = 0;
    noMoreRecords = false;
    urlMethod = "/api/v1/mails"; //默认

    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });
        this.state = {
            dataSource: dataSource.cloneWithRows({}),
            isLoading: true,
            page: 1,
            height: document.documentElement.clientHeight,
            longitude: 121,
            latitude: 31,
        };
    }

    //判断是否返回回来的；如果是返回的加载之前本地保留的数据，否则从后台加载
    componentDidMount() {
        let url =
            this.urlMethod +
            "?1=1" +
            "&type=recentContacts"+
            `&page=${this.state.page}` +
            "&pageSize=10";
        this.postList=[]; //初始化
        this.getPostList(url);
    }

    getPostList(url) {
        fetchAll(url)
            .then(res => {
                //没取到，表示到最后一页了
                if (res.result === "failed" || (res.recentContacts && res.recentContacts.length === 0)) {
                    this.noMoreRecords = true;
                    return;
                }
                let sendersWithNewMsgs = getLocalData("sendersWithNewMsgs");
                if(sendersWithNewMsgs === null){
                    sendersWithNewMsgs = {}
                }
                for(let i = 0; i < res.recentContacts.length; i++){
                    let lastMail = res.recentContacts[i].lastMail;
                    if(lastMail && lastMail.unreadCnt && lastMail.unreadCnt > 0){
                        let sender = res.recentContacts[i].contactInfo;
                        if(sender && sender.uid){
                            sendersWithNewMsgs[sender.uid] = true;
                        }
                    }
                }
                setLocalData("sendersWithNewMsgs", sendersWithNewMsgs);
                this.postList = this.postList.concat(res.recentContacts);
                this.setState({
                    dataSource: this.state.dataSource.cloneWithRows(this.postList),
                    isLoading: false,
                    page: this.state.page + 1,
                });
            })
            .catch(err => {
                Toast.fail(err.message, 3, null, false);
                console.log(err);
            });
    }

    refreshPage = () => {
        this.postList = [];
        this.noMoreRecords = false;
        this.setState({ page: 1 });
        let url =
            this.urlMethod +
            "?1=1" +
            "&type=recentContacts"+
            `&page=${this.state.page}` +
            "&pageSize=10";
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.getPostList(url);
            this.setState({
                isLoading: false,
            });
        }, 600);
    };

    //为了取到滚动条位置
    onScroll = event => {
        this.scrollTop = event.target.scrollTop;
    };

    onEndReached = event => {
        //如果已经到达最后一页，不再加载了
        if (this.noMoreRecords) {
            this.setState({ isLoading: false });
            return;
        }
        this.setState({ isLoading: true });
        let url =
            this.urlMethod +
            "?1=1" +
            "&type=recentContacts"+
            `&page=${this.state.page}` +
            "&pageSize=10";
        this.getPostList(url);
    };


    render() {
        const _renderSeparator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: "#F5F5F9",
                    height: 10,
                    borderTop: "1px solid #ECECED",
                    borderBottom: "1px solid #ECECED",
                }}
            />
        );

        const _renderRow = rowData => {
            return (
                <Link to={`/chat/${rowData.contactInfo.uid}`}>
                    <div id='chat_div'>
                        <div id='user_image'>
                            <Badge invisible={!(rowData.lastMail.unreadCnt && rowData.lastMail.unreadCnt > 0)} color={"secondary"} variant={"dot"}>
                                <Avatar style={{width:'50px',height:'50px'}} variant="rounded" alt="用户" src={rowData.contactInfo.portraitThumbnailUrl} />
                            </Badge>
                        </div>
                        <div id='chat_info'>
                            <div id='user'>{rowData.contactInfo.displayName}</div>
                            <div id='msg'>{
                                (rowData.lastMail.unreadCnt && rowData.lastMail.unreadCnt > 0 ? "(" + rowData.lastMail.unreadCnt + "条)" : "")
                                + (rowData.lastMail.picture && rowData.lastMail.picture !== "" ? ("图片") : rowData.lastMail.content)
                            } </div>
                        </div>
                        <div id='chat_time'>
                            <span>{formatTime(rowData.lastMail.createTime)}</span>
                        </div>
                    </div>
                </Link>
            );
        };

        return (
            <div id='msg_page'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        require("history").createHashHistory().goBack();
                    }}
                >
                    消息
                </NavBar>
                {this.postList.length === 0 ?
                    (<div><Result message="暂无消息…"/> </div>)
                    : (
                        <ListView
                            id="theMsgList"
                            dataSource={this.state.dataSource}
                            initialListSize={10}
                            renderRow={_renderRow}
                            renderSeparator={_renderSeparator}
                            style={{overflow: "auto",height: document.documentElement.clientHeight*4.5/5,}}
                            pageSize={10}
                            onScroll={this.onScroll}
                            scrollRenderAheadDistance={500}
                            onEndReached={this.onEndReached}
                            onEndReachedThreshold={10}
                            pullToRefresh={
                                <PullToRefresh
                                    onRefresh={this.refreshPage}
                                />
                            }
                        /> )}
            </div>
        );
    }
}
