import React, { Component } from "react";
import { Dialog } from "antd-mobile-v5";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import {fetchAll} from "../util/HttpUtil";

import "../css/area.less";
import "../css/rqSelector.less";
import 'antd/lib/auto-complete/style/css';


export default class PostDetailReference extends Component {
    constructor(props){
        super(props);
    }

    showComment(comment){
        let picList = [];
        picList.push(
            <PhotoProvider>
                {comment.pictures.map((item, index) => (
                    <PhotoConsumer key={index} src={item.url}>
                    {/*<PhotoConsumer key={index} src={item.url} intro={item.thumbnailUrl}>*/}
                        <img
                            src={item.thumbnailUrl}
                            alt=""
                            style={{
                                width: "200px",
                                margin: "0 auto", //水平居中
                                marginBottom: "10px",//下方有空
                            }}
                        />
                    </PhotoConsumer>
                ))}
            </PhotoProvider>
        );
        Dialog.alert({
            closeOnMaskClick: true
            , confirmText: "关闭"
            , bodyStyle: {"height" : "80%"}
            , content:
                (
                    <div>
                        <div>
                            {comment.content}
                        </div>
                        <div style={{ textAlign: "center" }}>
                            {picList}
                        </div>
                    </div>
                )
        })
    }

    getCommentAndShow(){
        fetchAll("/api/v1/posts/" + this.props.parentPostId + "/comments/" + this.props.commentId)
            .then(res => {
                //没取到，表示到最后一页了
                if (res.result === "success") {
                    this.showComment(res.comment);
                }else{
                    Dialog.alert({
                        closeOnMaskClick: true
                        , confirmText: "关闭"
                        , content: "没找到哎,试试别的吧！"
                    })
                }
            })
            .catch(err => {
                console.log(err)
                Dialog.alert({
                    closeOnMaskClick: true
                    , confirmText: "关闭"
                    , content: "没找到哎,试试别的吧！"
                })
            });
    }

    onReferenceClicked = (e) => {
        this.getCommentAndShow();
    }

    render() {
        let referenceAbstraction = this.props.referenceAbstraction;
        let replyToUserInfo = this.props.replyToUserInfo;
        let content = referenceAbstraction.content;
        return (
            <div style={{"backgroundColor": "rgb(225,225,225)", "color": "rgb(128,128,128)"}}
                 onClick={this.onReferenceClicked}
            >
                {(replyToUserInfo.displayName && replyToUserInfo.displayName !== "" ? replyToUserInfo.displayName : "住这儿用户") + "：" + referenceAbstraction.content}
                {referenceAbstraction.containsPictures ? (<div>{"<图片>"}</div>) : (<div style={{"display":"none"}}/>)}
            </div>
        );
    }
}