import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthRouter from './authRouter';
import Footer from './containers/footer';
import PasswordLoginPage from './containers/my/passwordLogin';
import VCodeLoginPageLogin from './containers/my/vCodeLogin';
import Register from './containers/my/register';
import PhoneBind from './containers/my/phoneBind';
import My from './containers/my/my';

import DetailPage from "./containers/post/detailPage";
import './index.css';
import * as serviceWorker from './serviceWorker';
import WriteMainPostPage from "./containers/post/WriteMainPostPage"
import WriteReplyPage from "./containers/post/WriteReplyPage"
import MsgList from './containers/my/msgList';
import Chat from './containers/my/chat.js';
import Setting from './containers/my/setting';
import SetArea from './containers/my/setArea';
import Location from './containers/location/Location';

import MyPostList from './containers/post/MyPostList'
import MyCollectList from './containers/post/MyCollectList'
import RecyclePage from './containers/post/RecyclePage'
import ExperiencePage from './containers/post/ExperiencePage'
import ActivityPage from './containers/post/ActivityPage'
import ReviewPage from './containers/post/ReviewPage'
import ReviewDetailPage from './containers/post/ReviewDetailPage'

import Guide from './containers/guide/guide';


ReactDOM.render(
    (<Router>
        <div>
            <Route path='/detail/:postId' component={DetailPage}/>

            <Route path='/post/recycle' component={RecyclePage}/>
            <Route path='/post/experience' component={ExperiencePage}/>
            <Route path='/post/activity' component={ActivityPage}/>

            <Route path='/my' component={My}/>
            <Route path='/pwdlogin/:prevRouter' component={PasswordLoginPage}/>
            <Route path='/pwdlogin' component={PasswordLoginPage}/>
            <Route path='/vcodelogin' component={VCodeLoginPageLogin}/>
            <Route path='/register' component={Register}/>
            <AuthRouter path='/bind' component={PhoneBind}/>
            <AuthRouter path='/reply/:parentPostId/:replyToCommentId/:category' component={WriteReplyPage}/>
            <AuthRouter path='/write/:category' component={WriteMainPostPage}/>
            <AuthRouter path='/setting' component={Setting}/>
            <AuthRouter path='/setArea' component={SetArea}/>
            <Route path='/msgList' component={MsgList}/>
            <Route path='/location' component={Location}/>
            <AuthRouter path='/myPostList/:userId' component={MyPostList}/>
            <AuthRouter path='/myCollectList/:userId' component={MyCollectList}/>
            <AuthRouter path='/admin/review' component={ReviewPage}/>
            <AuthRouter path='/admin/postreviews/:postOrCommentId' component={ReviewDetailPage}/>
            <Switch>
                <Route  path='/chat/:friendId' component={Chat}/>
                <Redirect exact from="/" to="/post/recycle" />
                <Footer/>
            </Switch>

        </div>
    </Router>),


    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
