import { SearchBar, NavBar, Icon } from "antd-mobile";

import React, { Component } from "react";
import PostList from "@/components/PostList";
import "@/css/readList.css";

export default class MyCollectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: null,
    };
  }

  onSearch = value => {
    console.log("onSearch", value);
    this.setState({
      searchValue: value,
    });
  };

  render() {
    return (
      <div>
        <NavBar
          mode="dark"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            require("history")
              .createHashHistory()
              .goBack();
          }}
        >
          我的收藏
        </NavBar>

        <div id="postlist_page">
          <SearchBar
            // style={{ marginBottom: "3px" }}
            placeholder="搜索"
            onSubmit={this.onSearch}
            onClear={this.onSearch}
          />
          <PostList
            from={"MyCollectList"}
            queryPostApi = {"/api/v1/postrefs"}
            queryParams = {"&type=collect"}
          />
        </div>
      </div>
    );
  }
}
