import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";

import "../css/postdetail.less";
import "../css/readList.css";
import "react-photo-view/dist/index.css";
import default_png from "@/icon/avater/default.png";
class UserPortrait extends Component {
    render() {
        return (
            <div className="detail_author">
                <Avatar
                    alt="icon"
                    style={{height: 25, width: 25}}
                    src={
                        this.props.portraitlUrl ? this.props.portraitlUrl : default_png
                    }
                />
                <div style={{"fontSize": "14px"}}>{this.props.displayName} </div>
            </div>
        )
    }
}
export default withRouter(UserPortrait);
