import { NavBar,Icon } from "antd-mobile";
import {SearchBar} from "antd-mobile-v5";
import React, { Component } from "react";
import PostList from "@/components/PostList";
import { getUserId } from "@/util/commonUtil";

import "@/css/readList.css";
import "@/css/rqSelector.less";
import debounce from "lodash/debounce";

export default class MyPostList extends Component {
    constructor(props) {
        super(props);
        this.searchKeyword = ""
        this.state = {
            searchValue: null,
            queryParams: "&userid=" + getUserId()
        };
        this.submitSearch = debounce (this.submitSearch, 1000);
    }

    searchByKeyword = value => {
        this.searchKeyword = value.trim();
        this.submitSearch();
    }

    submitSearch() {
        this.setState({queryParams: "&userid=" + getUserId() + this.makeKeywordParam()})
    }

    makeKeywordParam(){
        if(this.searchKeyword !== ""){
            return "&keyword=" + encodeURIComponent(this.searchKeyword);
        }else{
            return "";
        }
    }

    render() {
        return (
            <div>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => {
                        require("history").createHashHistory().goBack();
                    }}
                >
                    我的发布
                </NavBar>
                <div id="postlist_page">
                    <SearchBar
                        ref={n => (this.searchRef = n)}
                        placeholder="请输入搜索内容(小区名,标题,内容)"
                        // onSearch={this.searchByKeyword}
                        onChange={this.searchByKeyword}
                        style={{
                            width: "100%",
                            "--border-radius": "100px!important",
                            "--background": "#ffffff",
                            "--adm-color-primary": "#ffffff", //点击时边框颜色
                        }}
                    />
                    <PostList queryPostApi = {"/api/v1/search/posts"} queryParams = {this.state.queryParams}/>
                </div>
            </div>
        );
    }
}
