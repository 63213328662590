import React, { Component } from "react";
import {getSessionData, setSessionData, getUserAvatar, getLocalData} from "../../util/commonUtil";
import { DownOutline } from 'antd-mobile-icons';
import ReviewList from "../../components/ReviewList.js"

import "@/css/readList.css";
import "@/css/categorySelector.less";
import "@/css/rqSelector.less";


export default class ReviewPage extends Component {
    constructor(props, categoryId, filteredCategory) {
        super(props);
        this.state = {
            currentCityName : ""
            , queryParams : ""
        };
    }

    componentDidMount() {
        let currentCity = getLocalData("current_view_city");
        let cityName = "";
        if(currentCity !== null){
            cityName = currentCity.name;
        }else{
            cityName = "请选择城市"
        }

        this.setState({currentCityName: cityName});
        let accountInfo = getLocalData("accountInfo");
        if(accountInfo && accountInfo.displayName){
            this.setState({myUserName : accountInfo.displayName, myAvatar : getUserAvatar()})
        }else{
            this.setState({myUserName : "请登录", myAvatar : getUserAvatar()})
        }
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div>
                <div id="header">
                    <div className={"header_container"}>
                        <div id="address" onClick={() => {this.props.history.push("/location");}}>
                            <div>{this.state.currentCityName} <DownOutline fontSize={12} /></div>
                        </div>
                        <div>绿色生活从这里开始</div>
                        <div id="user" className="header_user" style={{ marginBottom: "10px" }}
                             onClick={() => {
                                 this.props.history.push("/my");
                             }}>
                            <div>
                                <img src={this.state.myAvatar} />
                            </div>
                            <div>{this.state.myUserName}</div>
                        </div>
                    </div>
                </div>
                <div id="postlist_page">
                    <ReviewList
                        queryPostApi = {"/api/v1/admin/postreviews"}
                        cacheKey = {"reviewPost"}
                    />
                </div>
            </div>
        );
    }
}
