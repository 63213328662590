import React, { Component,Space } from 'react';
import {NavLink} from 'react-router-dom';
import './footer.less';
import Badge from '@material-ui/core/Badge';
import {getUserId, setSessionData, getLocalData, getSessionData, checkNewMsgs} from "../util/commonUtil";
import beian_png from "@/icon/beian.png";

export default class Footer extends Component{
    constructor(props){
        super(props)
        this.getNewMsgFunc = null;
        this.state = {
            hasNewMsg : false
        }
        setSessionData("has_new_msg", false);
        this.footerRef = React.createRef();
    }

    componentDidMount() {
        checkNewMsgs();
        this.getNewMsgFunc = setInterval(this.checkHasNewMsgInterval, 1000);
        setSessionData("footer_height", this.footerRef.current.clientHeight);
    }

    componentWillUnmount() {
        if(this.getNewMsgFunc !==null ){
            clearInterval(this.getNewMsgFunc);
        }
        //防止报错：Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application
        this.setState = (state,callback)=>{
            return;
        };
    }

    checkHasNewMsgInterval = () => {
        if (getUserId() === null) {
            return;
        }
        let hasNewMsg = false;
        let sendersWithNewMsgs = getLocalData("sendersWithNewMsgs");
        if(sendersWithNewMsgs !== null && Object.keys(sendersWithNewMsgs).length > 0){
            hasNewMsg = true;
        }else if(getSessionData("has_new_msg")){
            hasNewMsg = true;
        }
        this.setState({hasNewMsg: hasNewMsg})
    }

    render(){
        let screenWidth = document.querySelector('body').offsetWidth
        let screenHeight = document.querySelector('body').offsetHeight
        let showed = screenWidth > screenHeight ? "block" : "none"
        let footerBottom = screenWidth > screenHeight ? "20px" : "0px"
        let background_size = screenWidth > screenHeight ? "0.8rem 0.3rem" : "0.8rem 0.8rem"
        return(
            <footer className="footer_box">
                <div className="footer_wrap" style={{"bottom":footerBottom}} ref={this.footerRef}>
                    <NavLink to="/post/recycle" exact activeClassName="active" className="footer_item">
                        <div className="svgbutton_sell" style={{"background-size": background_size}}></div>
                        <span className="footer_item_word">闲置</span>
                    </NavLink>
                    <NavLink to="/post/experience" activeClassName="active" className="footer_item">
                        <div className="svgbutton_experience" style={{"background-size": background_size}}></div>
                        <span className="footer_item_word">打听</span>
                    </NavLink>
                    <NavLink to="/post/activity"  activeClassName="active" className="footer_item">
                        <div className="svgbutton_more" style={{"background-size": background_size}}></div>
                        <span className="footer_item_word">活动</span>
                    </NavLink>
                        <NavLink to="/my" activeClassName="active" className="footer_item">
                            <div className="svgbutton_my" style={{"background-size": background_size}}>
                                <Badge
                                    invisible={!this.state.hasNewMsg}
                                    color={"secondary"}
                                    variant={"dot"}
                                    style={{"left": "100%", "top": "-5px"}}
                                />
                            </div>
                            <span className="footer_item_word">我的</span>
                        </NavLink>
                </div>
                <div className="beian-item" >
                    <a href="https://beian.miit.gov.cn/" target="_blank" style={{"display":showed}}>沪ICP备2022019050号-1</ a>
                    <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                    <img src={beian_png} style={{"width":"1%","height":"1%", "display":showed}}/>
                    <a href="https://beian.mps.gov.cn/#/query/webSearch?code=31011502400901" rel="noreferrer" target="_blank" style={{"display":showed}}>沪公网安备31011502400901</a>
                </div>
            </footer>
        )
    }
}
