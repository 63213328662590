import React, { Component } from 'react';
import { NavBar, Icon } from 'antd-mobile'; 

export default class Guide extends Component {
    constructor(props){
        super(props); 
    }

    goback = () => {
        require("history").createHashHistory().goBack();
        // this.state.history().goBack();
        // this.props.history.goback();
    }

    render() {
        return (

            <div>
                <NavBar
                    mode="light"
                    icon={<Icon type="left" />}
                    onLeftClick={this.goback}
                    rightContent={[
                        <Icon key="0" type="search" style={{ marginRight: '16px' }} />,
                        <Icon key="1" type="ellipsis" />,
                    ]}
                >欢迎使用
                </NavBar>
                打造绿色生活
        </div>);
    }
}
 