import { Dropdown, CheckList, SearchBar } from "antd-mobile-v5";
import React, { Component } from "react";
import PostList from "@/components/PostList";
import {getSessionData, setSessionData, getUserAvatar, getLocalData, checkNewMsgs} from "../../util/commonUtil";
import {fetchAll} from "../../util/HttpUtil";
import { DownOutline } from 'antd-mobile-icons';

import "@/css/readList.css";
import "@/css/categorySelector.less";
import "@/css/rqSelector.less";
import debounce from "lodash/debounce";
import {getCurrentLocation, getCityMap} from "../../util/LocationUtil";


export default class PostPageBase extends Component {
    constructor(props, categoryId, filteredCategory) {
        super(props);
        this.mainCategoryId = categoryId
        this.mainCategoryName = filteredCategory
        this.searchKeyword = ""
        this.searchTimer = undefined
        this.selectedCategoryIds = []
        this.selectedRQInfos = getSessionData("selectedRQInfos"); //保存需要放在session中共享的选中小区的信息，是多次搜索结果中选中的小区的总和
        //先取一次session，保证postlist控件在构造时就能拿到最新的查询参数
        if(this.selectedRQInfos === null){
            this.selectedRQInfos = {};
        }
        this.state = {
            currentCityName : ""
            , categoryIdNameMap : {}
            , filteredCategoryNames : filteredCategory
            , selectedCategoryIds : []
            , selectedRQIds : [] //同步小区选择列表(CheckList控件)用的
            , rqSearchResult : [] // 保存每次搜索小区时，搜索结果列表。每次改关键字搜索后刷新。其中的每一项，会在选择时动态地改变选中状态： isSelected = true or false
            , selectedRQText : "查看感兴趣的小区"
            , queryParams : "&category=" + this.mainCategoryId + this.makeKeywordParam() + this.makeRQParam() + this.makeCityAdCodeParam() + this.makeLocationParam()
        };
        this.onResiQuarterSearchChange = debounce (this.onResiQuarterSearchChange, 500);
    }

    componentDidMount() {
        checkNewMsgs();//每次点击页面时，触发一次检查是否有新消息，避免定时检查给服务器带来太大压力
        let currentCity = getLocalData("current_view_city");
        let cityName = "";
        if(currentCity !== null){
            cityName = currentCity.name;
        }else{
            cityName = "请选择城市"
        }
        this.selectedRQInfos = getSessionData("selectedRQInfos");
        if(this.selectedRQInfos == null){
            this.selectedRQInfos = {}
        }
        let tmp = [];
        let ids = Object.keys(this.selectedRQInfos);
        for(let i = 0; i < ids.length; i++){
            let id = ids[i];
            tmp.push(this.selectedRQInfos[id]);
        }

        this.setState({selectedRQIds : ids, rqSearchResult: tmp, currentCityName: cityName});
        this.updateSelectedRQText();
        this.getCategories();
        let resiQuarter = getSessionData('choose_area');
        if(resiQuarter){
            this.setState({resiQuarterName:resiQuarter.name, resiQuarterId:resiQuarter.id});
        }else{
            this.setState({resiQuarterName:'查看感兴趣的小区'})
        }
        let accountInfo = getLocalData("accountInfo");
        if(accountInfo && accountInfo.displayName){
            this.setState({myUserName : accountInfo.displayName, myAvatar : getUserAvatar()})
        }else{
            this.setState({myUserName : "请登录", myAvatar : getUserAvatar()})
        }
        this.submitSearch()
    }

    componentWillUnmount() {
        setSessionData("selectedRQInfos", this.selectedRQInfos)
    }

    getCurrentCityAdCode(){
        let currentCity = getLocalData("current_view_city");
        if(currentCity === null) {
            return null;
        }
        let city = getCityMap()[currentCity.name];
        if(city === null){
            return null;
        }else{
            return city.adCodes;
        }
    }

    updateSelectedRQText(){
        let ids = Object.keys(this.selectedRQInfos);
        if(ids.length === 0) {
            this.setState({selectedRQText: "查看感兴趣的小区"});
        }else{
            let text = "";
            for(let i = 0; i < ids.length; i++){
                let rqInfo = this.selectedRQInfos[ids[i]];
                if(i > 0){
                    text += ",";
                }
                text += rqInfo.name;
            }
            if(text.length > 20){
                text = text.substr(0, 20) + "...";
            }
            this.setState({selectedRQText: text});
        }
    }

    getCategories() {
        fetchAll("/api/v1/categories")
            .then(res => {
                for(var i = 0; i < res.categories.length; i++){
                    const c = res.categories[i];
                    if(c["id"] === this.mainCategoryId){
                        let categoryIdNameMap = {}
                        for(var j = 0; j < c["subCategories"].length; j++){
                            categoryIdNameMap[c["subCategories"][j].id] = c["subCategories"][j].name;
                        }
                        this.mainCategoryName = c["name"]
                        this.setState({categoryIdNameMap : categoryIdNameMap})
                        break;
                    }
                }
            })
            .catch(err => {
                this.postList = [];
                this.setState({
                    isLoading: false,
                });
            });
    }

    searchByKeyword = value => {
        this.searchKeyword = value.trim();
        this.submitSearch();
    }

    onCategoriesSelected = (selectedCategoryIds) => {
        this.selectedCategoryIds = [];
        let names = "类别过滤";
        if(selectedCategoryIds.length > 0) {
            this.selectedCategoryIds.push(selectedCategoryIds);
            names = "";
            for(let i = 0; i < selectedCategoryIds.length; i++){
                let id = selectedCategoryIds[i];
                let name = this.state.categoryIdNameMap[id];
                if(i > 0){
                    names += ",";
                }
                names += name;
            }
        }else{
            names = this.mainCategoryName;
        }
        if(names.length >= 12){
            names = names.substr(0, 9) + "...";
        }

        this.setState({selectedCategoryIds : selectedCategoryIds, filteredCategoryNames : names});
        this.submitSearch()
    }

    //小区搜索列表中，选择项目改变
    //在this.selectedRQInfos中缓存选择结果
    onRQSelectionListChanged = (selectedRQIds) => {
        for(let i = 0; i < this.state.rqSearchResult.length; i++){
            let id = this.state.rqSearchResult[i].id;
            if(selectedRQIds.includes(id)){
                this.selectedRQInfos[id] = this.state.rqSearchResult[i];
            }else{
                delete this.selectedRQInfos[id];
            }
        }
        this.updateSelectedRQText();
        this.setState({selectedRQIds : selectedRQIds});
        setSessionData("selectedRQInfos", this.selectedRQInfos)
        this.submitSearch()
    }

    submitSearch() {
        this.setState({queryParams: this.makeCategoryParam() + this.makeKeywordParam() + this.makeRQParam() + this.makeCityAdCodeParam() + this.makeLocationParam()})
    }

    makeCategoryParam(){
        let selectedAtLeastOneSubCate = this.selectedCategoryIds.length > 0;
        if(selectedAtLeastOneSubCate && this.selectedCategoryIds.length > 0) {
            return  "&category=" + encodeURIComponent(this.selectedCategoryIds.join(","))
        }else{
            return "&category=" + this.mainCategoryId
        }
    }

    makeKeywordParam(){
        if(this.searchKeyword !== ""){
            return "&keyword=" + encodeURIComponent(this.searchKeyword);
        }else{
            return "";
        }
    }

    makeRQParam(){
        let ids = Object.keys(this.selectedRQInfos);
        if(ids.length > 0){
            return "&resi-quarter-id=" + ids.join(",");
        }else{
            return "";
        }
    }

    makeCityAdCodeParam(){
        let currentCityAdCode = this.getCurrentCityAdCode();
        if(currentCityAdCode !== null){
            return "&city-code=" + encodeURIComponent(currentCityAdCode) //curentCityAdCode 是城市 + 各区的adcodes用逗号拼接的
        }else{
            return "";
        }
    }

    makeLocationParam(){
        let ids = Object.keys(this.selectedRQInfos);
        if(ids.length > 0){
            return "";//指定小区，则不需要定位距离过滤。因为指定的小区可能离定位很远，需要查看特定小区的信息
        }
        let location = getCurrentLocation();
        if(location === null) {
            return "";
        }
        if(!location.lat || !location.lng || !location.cityAdCode){
            //必须有城市和经纬度信息，才进行定位过滤，因为要判断当前定位的城市和当前查看的城市是否相同
            return "";
        }

        let defaultDistance = 10000;
        let currentCity = getLocalData("current_view_city");
        if(!currentCity || !currentCity.adCode){
            //没有当前查看的城市信息（应该只有初始化时，或者bug的情况），用定位过滤
            return "&center=" + encodeURIComponent(location.lat + "," + location.lng) + "&radius=" + defaultDistance;
        }
        if(location.cityAdCode !== currentCity.adCode){
            //当前定位城市和查看城市不相同，不用定位过滤。因为定位距离和cityCode条件会冲突
            return "";
        }
        return "&center=" + encodeURIComponent(location.lat + "," + location.lng) + "&radius=" + defaultDistance;
    }

    onResiQuarterSearchChange = value => {
        if(value.trim() === ""){
            return;
        }
        let url = "/api/v1/search/resi-quarters?keyword="+encodeURIComponent(value.trim())+"&page=1&size=10" + this.makeCityAdCodeParam() + this.makeLocationParam();
        fetchAll(url)
            .then(res => {
                //没取到，表示到最后一页了
                if (res.founds && res.founds.length === 0) {
                    // this.noMoreRecords=true;
                    //无搜索结果
                }
                //把本次搜索的，和缓存中原来选中的小区，一起放到下拉选择列表中。缓存中原来选中的放在前面
                let alreadySelectedIds = Object.keys(this.selectedRQInfos)
                let tmp = [];
                for(let i = 0; i < alreadySelectedIds.length; i++){
                    let id = alreadySelectedIds[i];
                    tmp.push(this.selectedRQInfos[id]);
                }
                for(let i = 0; i < res.founds.length; i++){
                    if(!alreadySelectedIds.includes(res.founds[i].id)){
                        tmp.push(res.founds[i]);
                    }
                }
                this.setState({
                    rqSearchResult: tmp//res.founds,
                });

            })
            .catch(err => {
                console.log(err);
            });
    };

    render() {
        return (
            <div>
                <div id="header">
                    <div className={"header_container"}>
                        <div id="address" onClick={() => {this.props.history.push("/location");}}>
                            <div>{this.state.currentCityName} <DownOutline fontSize={12} /></div>
                        </div>
                        <div>绿色生活从这里开始</div>
                        <div id="user" className="header_user" style={{ marginBottom: "10px" }}
                             onClick={() => {
                                 this.props.history.push("/my");
                             }}>
                            <div>
                                <img src={this.state.myAvatar} />
                            </div>
                            <div>{this.state.myUserName}</div>
                        </div>
                    </div>
                    <SearchBar
                        ref={n => (this.searchRef = n)}
                        placeholder="请输入搜索内容(小区名,标题,内容,发布者)"
                        onSearch={this.searchByKeyword}
                        style={{
                            width: "100%",
                            "--border-radius": "100px",
                            "--background": "#ffffff",
                            "--adm-color-primary": "#ffffff", //点击时边框颜色
                        }}
                    />
                    <div className={"search_filter"} style={{ marginTop: "10px", textAlign: "left" }}>
                        <Dropdown>
                            <Dropdown.Item key='subCategory' title={this.state.filteredCategoryNames}>
                                <CheckList multiple defaultValue={this.state.selectedCategoryIds} onChange={this.onCategoriesSelected}>
                                    {
                                        Object.keys(this.state.categoryIdNameMap).map((categoryId, index) => (
                                            <CheckList.Item key={categoryId} value={categoryId} >{this.state.categoryIdNameMap[categoryId]}</CheckList.Item>
                                        ))
                                    }
                                </CheckList>
                            </Dropdown.Item>
                        </Dropdown>
                        <div/>
                        <Dropdown>
                            <Dropdown.Item key={"ResiQuarterSelector"} title={this.state.selectedRQText} forceRender={true}>
                                <SearchBar
                                    ref={n => (this.searchRef = n)}
                                    placeholder="请输入小区名进行过滤"
                                    showCancelButton
                                    onChange={this.onResiQuarterSearchChange}
                                    style={{
                                        width: "100%",
                                        "--border-radius": "100px",
                                        "--background": "#ffffff",
                                        "--adm-color-primary": "#ffffff", //点击时边框颜色
                                    }}
                                />
                                <CheckList multiple defaultValue={this.state.selectedRQIds} onChange={this.onRQSelectionListChanged}>
                                    {
                                        this.state.rqSearchResult.map((item, index) => (
                                            <CheckList.Item key={item.id} value={item.id} >
                                                {item.name + "  " + (item.cityname?item.cityname:"") + (item.adname?item.adname:"")}
                                            </CheckList.Item>
                                        ))
                                    }
                                </CheckList>
                            </Dropdown.Item>
                        </Dropdown>
                    </div>
                </div>
                <div id="postlist_page">
                    <PostList
                        id={"postlist"}
                        queryPostApi = {"/api/v1/search/posts"}
                        queryParams = {this.state.queryParams}
                        cacheKey = {this.mainCategoryId}
                        mainCategory = {this.props.location.pathname.substr("/post/".length)}
                    />
                </div>
            </div>
        );
    }
}
