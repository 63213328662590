import default_png from "@/icon/avater/default.png";
import {fetchAll} from "./HttpUtil";
import moment from 'moment'
export const checkNewMsgs = () => {
    if (getUserId() === null) {
        return;
    }
    fetchAll("/api/v1/mails?type=newMsgCnt&page=1&pageSize=1")
        .then(res => {
            let hasNewMsg = false;
            if (res.result === "success" && res.mails && res.mails.length > 0){
                hasNewMsg = res.mails[0] > 0;
            }
            setSessionData("has_new_msg", hasNewMsg);
        })
        .catch(err => {
        });
}

export const getUserAvatar=()=>{
    let accountInfo = getLocalData("accountInfo");
    if(accountInfo && accountInfo.portraitThumbnailUrl) {
        return accountInfo.portraitThumbnailUrl;
    }else {
        return default_png;
    }
};

export const getUserId=()=>{
    let accountInfo = getLocalData("accountInfo");
    if(accountInfo !== null) {
        return accountInfo.uid;
    }
    return null;
};

export const getCurrentUser=()=>{
    let accountInfo = getLocalData("accountInfo");
    if(accountInfo){
      return accountInfo;
    }
    return null;
};

export const getRefreshTokenParam=()=>{
    let accountInfo = getCurrentUser();
    if(accountInfo){
      let userName = accountInfo.userName;
      let userId = accountInfo.uid;
      let refreshToken = getLocalData("refreshToken");
      return {refreshToken:refreshToken,userName:userName,userId:userId};
    }
    return null;
};

//localStorage
export const setLocalData=(key,data)=>{
    if(typeof(data)==='object'){
        localStorage.setItem(key,JSON.stringify(data))
    }else {
        localStorage.setItem(key,data)
    }
};

export const getLocalData=(key)=>{
    let value=null;
    try {
        value=JSON.parse(localStorage.getItem(key))
    }catch(err){
        value=localStorage.getItem(key)
    }
    return value;
};

export const removeLocalData=(key)=>{
    if (Array.isArray(key)){
        for (var i=0,l=key.length;i<l;i++){
            localStorage.removeItem(key[i]);
        }
    }else {
        localStorage.removeItem(key);
    }
};

//sessionStorage
export const setSessionData=(key,data)=>{
    try {
        sessionStorage.setItem(key,JSON.stringify(data))
    }catch(err){
        sessionStorage.setItem(key,data)
    }
};
export const getSessionData=(key)=>{
    let value=null;
    try {
        value=JSON.parse(sessionStorage.getItem(key))
    }catch(err){
        value=sessionStorage.getItem(key)
    }
    return value;
};

export const removeSessionData=(key)=>{
    if (Array.isArray(key)){
        for (var i=0,l=key.length;i<l;i++){
            sessionStorage.removeItem(key[i]);
        }
    }else {
        sessionStorage.removeItem(key);
    }
};


  /**
 * 时间戳格式转换以及计算
 * */
export function formatTime (time = 0, format = 'computed') {
    const now = new Date().getTime()
  
    if (!time) time = now
    if(typeof(time) === "string"){
        time = new Date(time).getTime()
    }
  
    while (time.toString().length < 13) time += '0'
  
    const date = new Date(time)
  
    date.getMonth()
    /** 参数集 年-月-日 时:分:秒 */
    const arg = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds()
    }
  
    /** 判断有没有指定的时间格式 */
    switch (format) {
      case 'YYYY-MM-DD hh:mm:ss':
        return moment(time).format('YYYY-MM-DD hh:mm:ss')
        //return `${arg.year}-${arg.month}-${arg.day} ${arg.hours}:${arg.minutes}:${arg.seconds}`
      case 'YYYY-MM-DD':
        return `${arg.year}-${arg.month}-${arg.day}`
      case 'MM-DD':
        return `${arg.month}-${arg.day}`
      case 'hh:mm:ss':
        return `${arg.hours}:${arg.minutes}:${arg.seconds}`
      case 'hh:mm':
        return `${arg.hours}:${arg.minutes}`
      case 'computed':			//判断是不是需要进行计算
        if (now > time) {
          const dt = Math.abs(time - now),    //时间已过去多少毫秒
            S = dt / 1000,    //秒
            M = S / 60,  //分
            H = M / 60,  //小时
            D = H / 24,   //天
            W = D / 7    //周
         /**
              ~~ ==>表示取整数部分 类似与 parseInt
          */
          if (~~W > 0 && W < 3) {
            return ~~W + '周前'
          } else if (D < 7 && ~~D > 0) {
            return ~~D + '天前'
          } else if (~~H > 0 && H < 24) {
            return ~~H + '小时前'
          } else if (~~M > 0 && M < 60) {
            return ~~M + '分钟前'
          } else if (~~S > 0 && S < 60) {
            return ~~S + '秒前'
          }
        } else {
          console.log('未来的时间')
        }
        return `${arg.year}-${arg.month}-${arg.day} ${arg.hours}:${arg.minutes}:${arg.seconds}`
    }
  } 