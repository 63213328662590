// 对话聊天组件
import React, { Component } from 'react';
import { NavBar, ListView, Icon,PullToRefresh, ImagePicker, Toast } from 'antd-mobile'
import { Button,TextArea } from 'antd-mobile-v5'
import {PhotoConsumer, PhotoProvider} from "react-photo-view";
import { getUserId, formatTime,getUserAvatar, getLocalData, setLocalData, getCurrentUser } from "@/util/commonUtil";
import {fetchBlob, fetchAll} from "../../util/HttpUtil";
import Avatar from "@material-ui/core/Avatar";
import MailReference from "../../components/MailReference";
import {ImageViewer} from "antd-mobile-v5";

import default_png from "@/icon/avater/default.png";
import add_png from "@/icon/add.png";
import "./msg.css";
import '@/css/chat.css'
import ImgUtil from "../../util/ImgUtil";

export default class Chat extends Component {
    postList = [];
    scrollTop = 0;
    totalPage = 0;
    noMoreRecords = false;

    friendId = "";
    friend = {};

    constructor(props){
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });
        this.titleHeight = 45
        this.tipsHeight = 22
        this.imagePickerHeight = 145
        this.state = {
            dataSource: dataSource.cloneWithRows({}),
            chatList: [],
            refreshing: false,
            down: true,
            page :1,
            content: '',  // 输入聊天的内容
            showSendTextButton: false,
            showImagePicker: false,
            pictureFiles: [],
            textSendBarHeight: 45,
        };
        this.friendId = this.props.match.params.friendId;
        this.lv = React.createRef();
    }

    componentDidMount() {
        let friendId = this.props.match.params.friendId;
        let url =
            "/api/v1/mails?type=private"+
            "&me=" + getUserId() +
            "&friend=" + friendId +
            `&page=1` +
            `&pageSize=10`;
        this.postList=[]; //初始化
        this.getChatList(url, true);
        let sendersWithNewMsgs = getLocalData("sendersWithNewMsgs");
        if(sendersWithNewMsgs !== null){
            delete sendersWithNewMsgs[friendId];//已读
            setLocalData("sendersWithNewMsgs", sendersWithNewMsgs);
        }
    }

    onRefresh(){
        let friendId = this.props.match.params.friendId;
        let url =
            "/api/v1/mails?type=private"+
            "&me=" + getUserId() +
            "&friend=" + friendId +
            `&page=1` +
            `&pageSize=10`;
        this.postList=[]; //初始化
        this.noMoreRecords=false;//以便可以继续重新查询
        this.setState({page:1});
        this.getChatList(url, false);
    }

    componentDidUpdate() {
    }

    handleSend = () => {
        let payload = {};
        payload.sender = getUserId();
        payload.receiver = this.friendId;
        payload.title = "";
        payload.content = this.state.content.trim();
        if(payload.content === ""){
            return;
        }
        this.setState({showSendTextButton: false})

        fetchAll("/api/v1/mails/private", payload,'POST')
            .then(responseData => {
                if(responseData.result === 'success' ){
                    //更新消息显示列表
                    this.onRefresh();
                }
                else
                    Toast.fail("对不起，消息发送失败。请稍后再试！", 3, null, false);
                this.setState({
                    reply_div_display: "none",
                });
            })
            .catch(err => {
                Toast.fail("消息发送失败。请稍后再试！", 3, null, false);
            });

        this.setState({ content: '', isShow: false })
    }

    onContentClicked = () => {
        this.setState({showImagePicker: false})
    }

    onPictureButtonClicked = () =>{
        this.setState({showImagePicker: true})
    }

    onSendPictureButtonClicked = () =>{
        let pictureFiles = this.state.pictureFiles;
        let currentUser = getCurrentUser();
        if(!currentUser || !getLocalData("token")){
            Toast.fail("登录已过期，请重新登录", 3, null, false);
            return;
        }
        let formData = new FormData();
        for (let i = 0; i < pictureFiles.length; i++) {
            formData.append("pic", pictureFiles[i].file);
        }
        fetchBlob("/api/v1/mails/private/pics?receiver=" + this.friendId, "POST",formData)
            .then(responseData => {
                this.onRefresh();
            })
            .catch(err => {
                Toast.fail("发送图片失败,请稍后重试", 3, null, false)
            });
        this.setState({showImagePicker: false})
    }

    //添加图片时，进行压缩
    onPictureChange = (pictureFiles, type, index) => {
        //重复对所有图片进行遍历，未压缩的进行压缩，以适应一次上传多个图片的情况
        if(type === 'add') {
            for (let i = 0; i < pictureFiles.length; i++) {
                let fileSize = pictureFiles[i].file.size / 1024;
                //大于1024k的图片进行压缩
                if (fileSize > 1024) {
                    ImgUtil.transformFile(pictureFiles[i].file).then(value => {
                        pictureFiles[i].file = value;
                    });
                }
            }
            if (pictureFiles.length > 9) {
                Toast.fail("最多上传9张图片", 3, null, false);
            }
            pictureFiles = pictureFiles.slice(0, 9)
            pictureFiles.map((file, index) => {
                file.orientation = 1 //保持朝向，不设置的话，框架会自动旋转以满足宽>高，导致图片被左或右旋90度
            });
        }
        this.setState({
            pictureFiles: pictureFiles,
        });
    };
    onImgClicked = (index, pictureFiles) => {
        let file = pictureFiles[index];
        ImageViewer.show({image: file.url})
    }

    getChatList(url, isFirstRequest) {
        fetchAll(url)
            .then(res => {
                //没取到，表示到最后一页了
                if (
                    res.result === "failed" ||
                    !res.mails ||
                    (res.mails && res.mails.length === 0)
                ) {
                    this.noMoreRecords = true;
                    return;
                }

                this.friend = res.friend;

                this.postList = this.postList.concat(res.mails);
                // this.postList = [...this.postList,...res.mails];
                let showList = [...this.postList]; //为了按时间倒序排展示,reverse()会改变数组本身

                this.setState({
                    dataSource: this.state.dataSource.cloneWithRows(showList.reverse()),
                    isLoading: false,
                    page: this.state.page + 1,
                });
                if (isFirstRequest && this.lv && this.lv.current) {
                    //滚动到最底端（最新位置），这里不知道怎么计算最底端，直接写一个极大值
                    this.lv.current.scrollTo(0, 1000000);
                }
            })
            .catch(err => {
                Toast.fail('网络故障，请稍后重试', 3, null, false);
            });
    }

    onPull = () => {
        //如果已经到达最后一页，不再加载了
        if (this.noMoreRecords) {
            this.setState({ isLoading: false });
            return;
        }
        this.setState({ isLoading: true });
        let friendId = this.props.match.params.friendId;
        let url =
            "/api/v1/mails?type=private"+
            "&me=" + getUserId() +
            "&friend=" + friendId +
            `&page=${this.state.page}` +
            `&pageSize=10`;
        this.getChatList(url, false);
    }

    onKeyDowned = (key, ctrl) =>{
        if(key.keyCode === 13){
            console.log("==== height: " + key.target.clientHeight)
        }
    }

    refreshMore=() => {
        this.setState({ refreshing: true });
        this.onPull();
        setTimeout(() => {
            this.setState({ refreshing: false });
        }, 500);
    }

    render() {
        const friendId =  this.props.match.params.friendId;//this.props.match.params.userid

        const _renderRow = rowData => {
            if(rowData.picture){
                rowData.pictureJson = JSON.parse(rowData.picture)
            }
            if(rowData.sender === friendId){
                return (
                    <div>
                        <div className="chat_item_receive">
                            <div/>
                            <Avatar
                                alt="icon"
                                style={{ height: 25, width: 25 }}
                                src={this.friend.portraitThumbnailUrl ? (
                                    this.friend.portraitThumbnailUrl
                                ) : default_png
                                }
                            />
                            {/* 加上whiteSpace:"pre"会有换行，但没遇到换行符不自动换行 */}
                            <div>
                                {
                                    rowData.pictureJson
                                    ?
                                    <PhotoProvider>
                                        <PhotoConsumer key={rowData.id} src={rowData.pictureJson.url}>
                                            <img
                                                src={rowData.pictureJson.thumbnailUrl}
                                                alt=""
                                                style={{
                                                    width: "200px",
                                                    margin: "0 auto", //水平居中
                                                    marginBottom: "10px",//下方有空
                                                    float: "left"
                                                }}
                                            />
                                        </PhotoConsumer>
                                    </PhotoProvider>
                                    :
                                    <div
                                        style={{
                                            whiteSpace: "pre-wrap",
                                            // overflow: "hidden",
                                            wordWrap: "break-word",
                                            wordBreak: "break-all",
                                            backgroundColor:'#F5F5F5',
                                            borderRadius:'5px',
                                            display:'inline-block',
                                            float:'left',
                                            padding:"5px 10px"
                                        }}
                                    >
                                        {rowData.content}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={"chat_refer_receive"}>
                            <div/>
                            <div>
                                {rowData.reference
                                    ? <MailReference referenceAbstraction={rowData.reference}/>
                                    : <div style={{"display":"none"}}/>
                                }
                            </div>
                        </div>
                        <div style={{textAlign:'center',color:'grey'}}>
                            {formatTime(rowData.createTime)}
                        </div>

                    </div>
                );}
            else {
                return (
                    <div>
                        <div className="chat_item_send">
                            <div/>
                            <div>
                                {
                                    rowData.pictureJson
                                        ?
                                        <PhotoProvider>
                                            <PhotoConsumer key={rowData.id} src={rowData.pictureJson.url}>
                                                <img
                                                    src={rowData.pictureJson.thumbnailUrl}
                                                    alt=""
                                                    style={{
                                                        width: "200px",
                                                        margin: "0 auto", //水平居中
                                                        marginBottom: "10px",//下方有空
                                                        float: "right"
                                                    }}
                                                />
                                            </PhotoConsumer>
                                        </PhotoProvider>
                                        :
                                        <div
                                            style={{
                                                whiteSpace: "pre-wrap",
                                                // overflow: "hidden",
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                                backgroundColor:'	#00EE00',
                                                borderRadius:'5px',
                                                // width:'0',
                                                display:'inline-block',
                                                float:'right',
                                                padding:"5px 10px"
                                            }}
                                        >
                                            {rowData.content}
                                        </div>
                                }
                            </div>
                            <Avatar
                                alt="icon"
                                style={{ height: 25, width: 25 }}
                                src={getUserAvatar() ? getUserAvatar() : default_png
                                }
                            />
                        </div>
                        <div className={"chat_refer_send"}>
                            <div>
                                {rowData.reference
                                    ? <MailReference referenceAbstraction={rowData.reference} parentPostId ={rowData.parentPostId} commentId = {rowData.replyToOriginCommentId}/>
                                    : <div style={{"display":"none"}}/>
                                }
                            </div>
                            <div/>
                        </div>
                        <div style={{textAlign:'center',color:'grey'}}>
                            {formatTime(rowData.createTime)}
                        </div>
                    </div>
                );
            }
        };

        //本页面从上到下共5个部分：标题栏；“下拉查看更多消息”提示条；消息体列表；文本填写栏；图片选择栏
        //其中图片选择栏平时是不可见的，点击文本赶写栏右侧的加号按钮时会从下面弹出来
        //消息体列表的高度随着图片选择栏的出现与否而动态计算（见ListView的style）
        return (
            <div id='chat-page'>
                <NavBar
                    style={{height: this.titleHeight + "px"}}
                    className='stick-top'
                    icon={<Icon type='left' />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    {this.friend.displayName}
                </NavBar>
                <div style={{textAlign:'center',color:'grey', height: this.tipsHeight + "px"}}>下拉查看更多消息</div>
                <div>
                    <div onClick={this.onContentClicked}>
                    <ListView
                        ref={this.lv}
                        dataSource={this.state.dataSource}
                        initialListSize={(this.postList&&this.postList.length>10) ? this.postList.length : 10}
                        renderRow={_renderRow}
                        style={{
                            height: document.documentElement.clientHeight - this.titleHeight - this.tipsHeight - this.state.textSendBarHeight + 5//不加5，最底下有缝隙，原因不详
                                - (this.state.showImagePicker ? this.imagePickerHeight : 0)
                                + "px",
                            overflow: "auto",
                        }}
                        pageSize={10}
                        scrollRenderAheadDistance={500}
                        onEndReachedThreshold={10}
                        pullToRefresh={
                            <PullToRefresh
                                onRefresh={this.refreshMore}
                            />
                        }
                    />
                    </div>
                </div>
                <div className={"chat_send_bar"}>
                    <div/>
                    <TextArea className={"chat_input_area"}
                        style={{"align-self": "center", "background": "white"}}
                        placeholder='请输入'
                        autoSize={{ minRows: 1, maxRows: 5 }}
                        value={this.state.content}
                        onChange={val => {
                            this.setState({content: val, showSendTextButton: val.trim() !== ""})
                        }}
                        onFocus={() => this.setState({ isShow: false, showImagePicker: false })}
                    />
                    <div >
                        {
                            this.state.showSendTextButton
                                ?
                                <Button onClick={this.handleSend} style={
                                    {
                                        "color":"white"
                                        ,"background":"rgb(110,190,90)"
                                        ,"border-radius": "10px"
                                        ,"width": "65px"
                                        ,"height": "36px"
                                        ,"top": "10%"
                                        ,"font-size": "15px"
                                    }
                                }>
                                    发送
                                </Button>
                                :
                                <div onClick={this.onPictureButtonClicked}>
                                    <img src={add_png} style={
                                        {
                                            "position": "relative"
                                            , "left": "16px"
                                            , "top": "6px"
                                        }
                                    }/>
                                </div>
                        }
                    </div>
                </div>
                <div style={this.state.showImagePicker
                    ? {"display": "block", "position": "relative", "height": this.imagePickerHeight,"background": "rgb(245,245,245)"}
                    : {"display": "none", "position": "relative", "height": this.imagePickerHeight,"background": "rgb(245,245,245)"}}>
                    {/*<ImagePicker onChange={this.onPictureChange}*/}
                    {/*             files={this.state.pictureFiles}*/}
                    {/*             selectable={this.state.pictureFiles.length < 5}*/}
                    {/*             multiple={true}*/}
                    {/*/>*/}

                    <ImagePicker
                        length={5}
                        multiple={true}
                        onChange={this.onPictureChange}
                        onImageClick={this.onImgClicked}
                        files={this.state.pictureFiles}
                        selectable={this.state.pictureFiles.length < 9}
                    />
                    <div className={"chat_send_picture_button"}>
                        <div/>
                        <Button onClick={this.onSendPictureButtonClicked} style={
                            {
                                "color":"white"
                                ,"background":"rgb(110,190,90)"
                                ,"border-radius": "10px"
                            }
                        }>
                            发送
                        </Button>
                        <div/>
                    </div>
                </div>
            </div>
        );
    }
}
