import React from 'react';  
import { Popover, NavBar, Icon,Toast,Modal,List } from 'antd-mobile';
import copy from 'copy-to-clipboard'; 

import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import '@/css/detailheader.less'


const Item = Popover.Item;

const myImg = src => <img src={`https://gw.alipayobjects.com/zos/rmsportal/${src}.svg`} className="am-icon am-icon-xs" alt="" />;
export default class DetailHeader extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      visible: false,
      selected: '',
    }
  } 

  onSelect = (opt) => {
    if(opt.key==='1'){
      copy(window.location.href);
      Toast.success('该文链接已复制成功!可以进行粘贴分享！', 3, null, false);
    }else{
      this.showModal();
      // this.onCreatePic();
      // Toast.success('包含该文地址的图片已生成!使用图片进行分享更方便！');
    }

    this.setState({
      visible: false,
      selected: opt.props.value,
    });
  };

  onCreatePic =() =>{ 
    let picEle = document.getElementById('sharepic');
    picEle.style.display = 'block';
    html2canvas(picEle, {
      allowTaint: false,
      useCORS: true,
  }).then(function (canvas) {
      // toImage
      const dataImg = new Image()
      dataImg.src = canvas.toDataURL('image/png')
      picEle.style.display = 'none'; //保存为图片后设置该div页面不可见
      const alink = document.createElement("a");
      alink.href = dataImg.src;
      alink.download = "shareImg.jpg";
      alink.click();
  });
  }
  handleVisibleChange = (visible) => {
    this.setState({
      visible,
    });
  };
 
  showModal = ()=> {
     this.setState({
      showModal: true,
    });
  }
  onClose =  () => {
    this.setState({
      showModal: false,
    });
  }
  render() {
    return (<div>
      { <NavBar
        mode="dark"
        icon={<Icon type="left" />}
        onLeftClick={() => {require("history").createHashHistory().goBack();}}
        rightContent={
          <Popover mask
            overlayClassName="fortest"
            overlayStyle={{ color: 'currentColor' }}
            visible={this.state.visible}
            overlay={[
              (<Item key="1" value="copyUrl" icon={myImg('tOtXhkIWzwotgGSeptou')}  >复制链接</Item>),
              (<Item key="2" value="createPic" icon={myImg('PKAgAqZWJVNwKsAJSmXd')} style={{ whiteSpace: 'nowrap' }}>生成二维码</Item>),
              ,
            ]}
            align={{
              overflow: { adjustY: 0, adjustX: 0 },
              offset: [-10, 0],
            }}
            onVisibleChange={this.handleVisibleChange}
            onSelect={this.onSelect}
          >
            <div style={{
              height: '100%',
              padding: '0 15px',
              marginRight: '-15px',
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <Icon type="ellipsis" />
            </div>
          </Popover>
        }
      >
         {this.props.title.slice(0,16)}
      </NavBar> }

      <div id='sharepic'  
       style={{display:'none',zIndex:-10}}
      >
        <div className='share_pic' >
        <div className='leaf_icon' > </div>
        <List>
        <List.Item>美好生活，就在这里</List.Item>
        <List.Item ><div style={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}>我在**上发布了《{this.props.title}》，扫描下方二维码查看吧！</div></List.Item>
         </List>
         </div>
        <div style={{textAlign:'center'}}><QRCode  value={window.location.href} /></div>
      </div>

      <Modal
          visible={this.state.showModal}
          transparent
          closable
          onClose={this.onClose}
          title="  " 
          footer={[{ text: '是', onPress: () => {this.onCreatePic(); this.setState({showModal: false});}},
          { text: '否', onPress: () => {this.setState({showModal: false});} }
        ]} 
        >
            <div style={{ height: 80 }}>
            <br></br>将生成包含本文链接二维码的图片，并下载到手机中，方便您与他人进行分享。 
          </div>
        </Modal>
    </div>);
  }
} 